import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import axios from 'axios';
import { apiRoute } from '../constants/api-constants';
import Cookies from 'universal-cookie';
import { NavigateFunction } from 'react-router-dom';
import { logOut } from '../function-utils/log-out';

interface AuthContextType {
  // eslint-disable-next-line
  setUser: (user: any | null | undefined) => void;
  // eslint-disable-next-line
  user: any | null | undefined;
  cookies: Cookies;
  loading: boolean;
  refetchUser: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

interface AuthProviderProps {
  children: ReactNode;
  navigate?: NavigateFunction;
}

export const AuthProvider = ({ children, navigate }: AuthProviderProps) => {
  // eslint-disable-next-line
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();
  const token = cookies.get('authorization_token');

  const checkAuth = async () => {
    try {
      if (token) {
        const response = await axios.get(`${apiRoute}/auth`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.user) {
          setUser(response.data.user);
        } else {
          await logOut(setUser, navigate);
        }
      } else {
        await logOut(setUser, navigate);
      }
    } catch (error) {
      await logOut(setUser, navigate);
    } finally {
      setLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${apiRoute}/refresh-token`);
      const newToken = response.data.token;
      cookies.set('authorization_token', newToken);
      setUser(response.data.user);
    } catch (error) {
      await logOut(setUser, navigate);
    }
  };

  const refetchUser = async () => {
    try {
      const response = await axios.get(`${apiRoute}/auth`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Refetch User Response:', response.data); // Log the response
      if (response.data.user) {
        setUser(response.data.user); // Update the user context
      } else {
        console.error('User data not found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    if (token) {
      checkAuth();

      const refreshTime = 55 * 60 * 1000;
      const refreshTimer = setInterval(() => {
        refreshToken();
      }, refreshTime);

      return () => clearInterval(refreshTimer);
    } else {
      setLoading(false);
    }
  }, [token]);

  const authContextValue: AuthContextType = {
    setUser,
    user,
    cookies,
    loading,
    refetchUser,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
