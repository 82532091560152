import React, { useEffect, useState } from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { Cookie } from 'universal-cookie';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
} from '../../layout/layout-container.styled';
import { TopMenu } from '../../layout/top-menu/top-menu';
import { Sidebar } from '../../layout/sidebar/sidebar';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { LeftArrow } from '../../icons/left-arrow';
import {
  BackContainer,
  EmployeeInfoWrapper,
  TabChangeButton,
  TabButtons,
} from './employee.styled';
import { fetchItemById } from '../../requests/requests';
import { colors } from '../../../style-utils/colors';
import { NewEmployeeDataTypes } from './add-employee';
import EmployeeDayOffTab from './employee-timeoff-tab';
import { EmployeeDocumentsTab } from './employee-documents-tab';
import { Text, font } from '../../../style-utils/text';
import { EmployeePersonalInfoTab } from './employee-personal-info-tab';
import { EmployeeContractDetailsTab } from './employee-contract-details-tab';
import axios from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { EmployeeJobData } from '../../../interfaces/employee-job-interfaces';
import EmployeeOnboardingTab from './employee-onboarding-tab/employee-onboarding-tab';
import NotificationPreferences from './notification-preferences/notification-preferences';
import { Spinner } from '../../icons/spinner';
import { EmployeePerformanceTab } from './employee-perfromance-tab/employee-performance-tab';
import EmployeeEducationTab from './employee-education-tab/employee-education-tab';

export const employeePersonalInfo = {
  dateOfBirth: '1990-05-15',
  address: '123 Main Street, Cityville, ',
  country: 'United States',
  nationality: 'American',
  job_position: 'Software Engineer',
  seniority: 'Senior',
  primary_technology: 'JavaScript',
  department: 'Engineering',
};

export interface EmployeeDocumentsTypes {
  id: string;
  employee_id: string;
  company_id: string;
  document_name: string;
  storage_location: string;
  upload_date: string;
  comments: string;
  profile_picture: string;
}

interface EmployeeProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  company: any;
  cookies?: Cookie;
  // eslint-disable-next-line
  updateEmployeeData: (employee: NewEmployeeDataTypes) => void;
}

export const Employee: React.FC<EmployeeProps> = ({ navigate, company }) => {
  const [employee, setEmployee] = useState<NewEmployeeDataTypes>();
  const [selectedTab, setSelectedTab] = useState<string | null>(
    'contract_details'
  );
  const [companyJobs, setCompanyJobs] = useState<EmployeeJobData[]>([]);
  const [employeeDocuments, setEmployeeDocuments] = useState<
    EmployeeDocumentsTypes[]
  >([]);
  const [personalDetailsEditMode, setPersonalDetailsEditMode] =
    useState<boolean>(false);
  const [contractDetailsEditMode, setContractDetailsEditMode] =
    useState<boolean>(false);

  const height = useWindowHeight();

  const { id: paramsId } = useParams();
  const { id: companyEmployeeId } = company;

  const employeeId = paramsId || companyEmployeeId;

  const employeeFromDB = async () => {
    if (employeeId) {
      const response = await fetchItemById(`/employee`, `${employeeId}`);
      setEmployee(response as NewEmployeeDataTypes);
    }
  };

  const employeeDocumentsFromDB = async () => {
    console.log('Gi zema podatocite');
    try {
      const response = await fetchItemById(
        `get-employee-documents`,
        `${employeeId}`
      );
      setEmployeeDocuments(response as EmployeeDocumentsTypes[]);
    } catch (err) {
      console.log('Error fetching employee documents');
      setEmployeeDocuments([]);
    }
  };

  const jobOffersFromDB = async () => {
    let response: EmployeeJobData[] = [];

    if (company.id) {
      axios
        .get(`${apiRoute}/employee_job_positions/${company.id}`)
        .then((res) => {
          if (res.data && res.data.data) {
            response = res.data.data;
          }
          setCompanyJobs(response);
        })
        .catch((error) => {
          console.error('Error fetching job offers:', error);
          setCompanyJobs([]);
        });
    } else {
      setCompanyJobs(response);
    }
  };

  useEffect(() => {
    jobOffersFromDB();
  }, [employeeId]);

  useEffect(() => {
    employeeFromDB();
  }, [employeeId, personalDetailsEditMode, contractDetailsEditMode]);

  useEffect(() => {
    employeeDocumentsFromDB();
  }, [employeeId]);

  const handleRenderComponent = (componentName: string) => {
    setSelectedTab(componentName);
  };

  const handleToggleEditMode = (toggleFor: string) => {
    switch (toggleFor) {
      case 'personal':
        setPersonalDetailsEditMode((prevState) => !prevState);

        return;
      case 'contract':
        setContractDetailsEditMode((prevState) => !prevState);

        return;
    }
  };

  if (!employeeId) {
    return <Spinner />;
  }

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={company} selectedOption={''} navigate={navigate} />
        <ContentWrapper bgColor={colors.creme_100}>
          {company.entityType === 'company' && (
            <BackContainer
              onClick={() => navigate(`/${company.name}/employees`)}
            >
              <LeftArrow />
              <Text
                display="block"
                color={colors.neutral_400}
                mt={0}
                mb={0}
                fontFamily={font.family}
              >
                Back to Employee Management
              </Text>
            </BackContainer>
          )}
          {employee && (
            <EmployeePersonalInfoTab
              employee={employee}
              companyJobs={companyJobs}
              personalDetailsEditMode={personalDetailsEditMode}
              handleToggleEditMode={handleToggleEditMode}
              employeeFromDB={employeeFromDB}
              company={company}
            />
          )}

          <EmployeeInfoWrapper>
            <TabButtons>
              <TabChangeButton
                active={selectedTab === 'contract_details'}
                onClick={() => handleRenderComponent('contract_details')}
              >
                Contract Details
              </TabChangeButton>
              <TabChangeButton
                active={selectedTab === 'education'}
                onClick={() => handleRenderComponent('education')}
              >
                Education
              </TabChangeButton>
              <TabChangeButton
                active={selectedTab === 'performance'}
                onClick={() => handleRenderComponent('performance')}
              >
                Performance
              </TabChangeButton>
              <TabChangeButton
                active={selectedTab === 'time_off'}
                onClick={() => handleRenderComponent('time_off')}
              >
                Time off
              </TabChangeButton>
              <TabChangeButton
                active={selectedTab === 'documents'}
                onClick={() => handleRenderComponent('documents')}
              >
                Documents
              </TabChangeButton>
              {company.entityType === 'company' && (
                <TabChangeButton
                  active={selectedTab === 'onboarding'}
                  onClick={() => handleRenderComponent('onboarding')}
                >
                  Onboarding
                </TabChangeButton>
              )}
              <TabChangeButton
                active={selectedTab === 'notifications'}
                onClick={() => handleRenderComponent('notifications')}
              >
                Notifications
              </TabChangeButton>
            </TabButtons>
          </EmployeeInfoWrapper>
          <EmployeeInfoWrapper>
            {selectedTab === 'contract_details' && employee && (
              <EmployeeContractDetailsTab
                company={company}
                employee={employee}
                handleToggleEditMode={handleToggleEditMode}
                contractDetailsEditMode={contractDetailsEditMode}
              />
            )}
            {selectedTab === 'education' && employee && (
              <EmployeeEducationTab employee={employee} />
            )}
            {selectedTab === 'performance' && employee && (
              <EmployeePerformanceTab />
            )}
            {selectedTab === `time_off` && employee && (
              <EmployeeDayOffTab
                employee={employee}
                employeeFromDB={employeeFromDB}
              />
            )}
            {selectedTab === `documents` && employee && (
              <EmployeeDocumentsTab
                company={company}
                employee={employee}
                employeeDocuments={employeeDocuments}
                employeeDocumentsFromDB={employeeDocumentsFromDB}
              />
            )}
            {selectedTab === `onboarding` && employee && (
              <EmployeeOnboardingTab company={company} employee={employee} />
            )}
            {selectedTab === 'notifications' && employee && (
              <NotificationPreferences entityId={employee?.id} />
            )}
          </EmployeeInfoWrapper>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
