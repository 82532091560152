const neutral = {
  neutral_50: '#f3f2f2',
  neutral_100: '#dad9d9',
  neutral_200: '#c2c1c0',
  neutral_300: '#aba9a7',
  neutral_400: '#94918f',
  neutral_500: '#898684',
  neutral_600: '#736f6d',
  neutral_700: '#5e5a57',
  neutral_800: '#403b38',
  neutral_900: '#2e2926',
};

const primary = {
  primary_50: '#ffe7e1',
  primary_100: '#ffc2b5',
  primary_200: '#fe9d8a',
  primary_300: '#f9836f',
  primary_400: '#f26954',
  primary_500: '#e94e3c',
  primary_600: '#c92c23',
  primary_700: '#aa000d',
  primary_800: '#8b0000',
  primary_900: '#600000',
};

const secondary = {
  secondary_50: '#e4eaf5',
  secondary_100: '#bccce6',
  secondary_200: '#93afd7',
  secondary_300: '#779ccc',
  secondary_400: '#5789c2',
  secondary_500: '#2e77b8',
  secondary_600: '#006aa0',
  secondary_700: '#00437d',
  secondary_800: '#002f67',
  secondary_900: '#001e51',
};

const creme = {
  creme_50: '#fffdf8',
  creme_100: '#f5f4f0',
  creme_200: '#e0e0dd',
  creme_300: '#c2c2c0',
  creme_400: '#afafad',
  creme_500: '#9c9c9b',
  creme_600: '#808080',
};

const base = {
  base_white: '#fdfdfd',
  base_black: '#010101',
};

const other = {
  purple_light: '#C4C0ED',
  purple_dark: '#382F9F',
  purple_label: '#8C67C9',
  white: '#fff',
  black: '#000',
  grey: '#637083',
  grey_light: '#C7CED1',
  grey_dark: '#333',
  secondary: '#637083',
  primary: '#26D9D9',
  primary_dark: '#02c4c4',
  error: '#E23E3E',
  active: '#FBFCFF',
  dashboard_light_grey: '#F6F7F7',
  success_green: '#00ab41',
  pending_yellow: '#FCB001',
  blue: '#0061A0',
  dark_blue: '#2E77B8',
};

const colors = {
  ...neutral,
  ...primary,
  ...secondary,
  ...creme,
  ...base,
  ...other,
};

export { neutral, primary, secondary, creme, base, other, colors };

// export const colors = {
//   purple_light: '#C4C0ED',
//   purple_dark: '#382F9F',
//   purple_label: '#8C67C9',
//   white: '#fff',
//   black: '#000',
//   grey: '#637083',
//   grey_light: '#C7CED1',
//   grey_dark: '#333',
//   secondary: '#637083',
//   primary: '#26D9D9',
//   primary_dark: '#02c4c4',
//   error: '#E23E3E',
//   active: '#FBFCFF',
//   dashboard_light_grey: '#F6F7F7',
//   creme_50: '#FFFDF8',
//   creme_100: '#F5F4F0',
//   creme_500: '#AFAFAD',
//   primary_50: '#FFE7E1',
//   primary_100: '#FFC2B5',
//   primary_300: '#F9836F',
//   primary_400: '#F26954',
//   primary_500: '#E94E3C',
//   primary_600: '#C92C23',
//   primary_700: '#AA000D',
//   primary_800: '#8B0000',
//   secondary_50: '#E4EAF5',
//   secondary_100: '#BCCCE6',
//   secondary_200: '#93AFD7',
//   secondary_300: '#779CCC',
//   secondary_500: '#2E77B8',
//   secondary_600: '#0061A0',
//   secondary_700: '#00437D',
//   neutral_50: '#F3F2F2',
//   neutral_400: '#94918F',
//   neutral_600: '#657374',
//   neutral_700: '#5E5A57',
//   neutral_900: '#2E2926',
//   base_black: '#010101',
//   base_white: '#FDFDFD',
// };
