import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../function-utils/log-out';
import { PaymentPlans } from '../payment-plans/payment-plan';
import { useAuthForms } from '../hooks/use-auth-forms';
import { loadSubscriptionPlans } from '../../function-utils/subscription-plan-utils';
import { SubscriptionPlan } from '../pages/super-admin/subscription-plans/interface';
import axios from 'axios';
import { apiRoute } from '../../constants/api-constants';
import { loadStripe } from '@stripe/stripe-js';
import { Spinner } from '../icons/spinner';

interface ChangePlanPageProps {
  // eslint-disable-next-line
  user: any;
  // eslint-disable-next-line
  setUser: (user: any | null) => void;
  refetchUser: () => Promise<void>;
}

export const ChangePlanPage: React.FC<ChangePlanPageProps> = ({
  user,
  setUser,
  refetchUser,
}) => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { signUpData, setSignUpData } = useAuthForms();
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);
  const [selectedPlanAmount, setSelectedPlanAmount] = useState<number | null>(
    null
  );

  useEffect(() => {
    let isMounted = true;

    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plans = await loadSubscriptionPlans();
        if (isMounted) {
          setPlans(plans);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching subscription plans:', error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const fetchUser = async () => {
      setUser(null);
      await refetchUser();
      console.log('User after refetch:', user); // Log the user after refetch
    };

    fetchPlans();
    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  const setSelectedPlan = (planId: string, amount: number) => {
    setSelectedPlanId(planId);
    setSelectedPlanAmount(amount);
    console.log(`Selected Plan ID: ${planId}, Amount: ${amount}`);
  };

  const handleUpgrade = async () => {
    if (selectedPlanId && selectedPlanAmount) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${apiRoute}/create-checkout-session`,
          {
            planId: selectedPlanId,
            customerEmail: user.email,
          }
        );

        const { checkoutSessionId } = response.data;

        const stripe = await loadStripe(
          process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
        );

        if (!stripe) {
          console.error('Stripe failed to initialize.');
          setLoading(false);

          return;
        }

        const stripeResult = await stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        });

        if (stripeResult.error) {
          console.error(
            'Error redirecting to Stripe checkout:',
            stripeResult.error.message
          );
        } else {
          handleLogout();
          navigate('/payment-success');
        }
        // eslint-disable-next-line
      } catch (error: any) {
        console.error(
          'Error during plan upgrade:',
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    } else {
      console.error('Please select a plan before upgrading.');
    }
  };

  const handleLogout = async () => {
    setUser(null);
    await logOut(setUser, navigate);
  };

  console.log(user);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div key={user}>
      <h1>Your Trial Has Expired</h1>
      <p>To continue using our services, please upgrade your plan.</p>
      <PaymentPlans
        isSignUp={false}
        setSignUpData={setSignUpData}
        signUpData={signUpData}
        plans={plans}
        setSelectedPlan={setSelectedPlan}
        defaultSelectedPlanId={user.selected_plan_id}
      />
      <button onClick={handleUpgrade} disabled={loading}>
        {loading ? <Spinner /> : 'Upgrade Plan'}
      </button>
      <button onClick={handleLogout} style={{ marginLeft: '10px' }}>
        Logout
      </button>
    </div>
  );
};
