import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import BackToDashboard from '../../settings/components/back-btn/back-btn';
import { Chat } from '../../../chat/chat';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { SurveyDetails } from './components/survey-details/survey-details';

interface SurveyComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
}

export const SurveyDetailsMainPage: React.FC<SurveyComponentProps> = ({
  user,
  navigate,
}) => {
  const height = useWindowHeight();

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={user} selectedOption={''} navigate={navigate} />
        <ContentWrapper style={{ padding: '45px' }}>
          <BackToDashboard user={user} navigate={navigate} />
          <SurveyDetails />
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
