import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth-provider';
import { Spinner } from '../pages/applicants/candidates/candidates-table-components/candidates-table-comments/candidate-comments.styled';

interface ProtectedRouteProps {
  children: JSX.Element;
  entityType: string;
  requiredEntityType: string;
}

export const ProtectedRoute = ({
  children,
  entityType,
  requiredEntityType,
}: ProtectedRouteProps) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (entityType !== requiredEntityType) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};
