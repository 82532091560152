import React, { useState } from 'react';
import { StyledTabsMainWrapper } from './tabs-main.styled';
import SelectColorsTab from './components/tabs/change-colors/change-colors';
import SettingsTabSelector from './components/tab-selector/tab-selector';
import { DefaultComponentProps } from '../../../../app-router/component-props';
import JobPositionTab from './components/tabs/job-position/job-position';
import { UserSettings } from './components/tabs/user-settings.tsx/user-settings';
import CompanySettingsOnboardingTab from './components/tabs/onboarding/onboarding';
import NotificationPreferences from '../../../employees/notification-preferences/notification-preferences';
import { SubscriptionPlans } from './components/tabs/subscription-plans/subscription-plans';

const SettingsTabs: React.FC<DefaultComponentProps> = ({ user, cookies }) => {
  const [activeTabId, setActiveTabId] = useState<number>(2);

  const handleTabChange = (tabId: number) => {
    setActiveTabId(tabId);
  };

  const tabs = [
    null,
    <SubscriptionPlans key={1} user={user} cookies={cookies} />,
    <SelectColorsTab key={2} user={user} cookies={cookies} />,
    <UserSettings key={3} user={user} />,
    <JobPositionTab key={4} user={user} />,
    <CompanySettingsOnboardingTab key={5} user={user} />,
    <NotificationPreferences key={6} entityId={user.id} />,
  ];

  return (
    <StyledTabsMainWrapper>
      <SettingsTabSelector
        onTabChange={handleTabChange}
        activeTabId={activeTabId}
      />
      {tabs[activeTabId]}
    </StyledTabsMainWrapper>
  );
};

export default SettingsTabs;
