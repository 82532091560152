import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';
import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { useAuth } from '../../../../context/auth-provider';

interface NotificationPreferencesProps {
  entityId?: string;
}

const NotificationPreferences: React.FC<NotificationPreferencesProps> = ({
  entityId = 'id was not passed correctly',
}) => {
  const { setUser, user } = useAuth();
  const [receiveEmail, setReceiveEmail] = useState(true);
  const [receiveInApp, setReceiveInApp] = useState(true);
  const [receiveEdit, setReceiveEdit] = useState(true);
  const [receiveTimeOff, setReceiveTimeOff] = useState(true);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await axios.get(
          `${apiRoute}/${entityId}/notification-preferences`
        );
        setReceiveEmail(response.data.receive_email_notifications);
        setReceiveInApp(response.data.receive_in_app_notifications);
        setReceiveEdit(response.data.receive_edit_notifications);
        setReceiveTimeOff(response.data.receive_time_off_notifications);
      } catch (error) {
        console.error('Error fetching notification preferences:', error);
      }
    };

    if (entityId !== 'id was not passed correctly') {
      fetchPreferences();
    }
  }, [entityId]);

  const handleSavePreferences = async () => {
    try {
      await axios.put(`${apiRoute}/${entityId}/notification-preferences`, {
        receive_email_notifications: receiveEmail,
        receive_in_app_notifications: receiveInApp,
        receive_edit_notifications: receiveEdit,
        receive_time_off_notifications: receiveTimeOff,
      });
      alert('Preferences updated successfully');

      await refreshUser();
    } catch (error) {
      console.error('Error updating notification preferences:', error);
    }
  };

  const refreshUser = async () => {
    try {
      const response = await axios.get(`${apiRoute}/auth`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  return (
    <PreferencesWrapper key={entityId}>
      <Label>
        Receive Email Notifications:
        <Input
          type="checkbox"
          checked={receiveEmail}
          onChange={() => setReceiveEmail(!receiveEmail)}
        />
      </Label>
      <Label>
        Receive In-App Notifications:
        <Input
          type="checkbox"
          checked={receiveInApp}
          onChange={() => setReceiveInApp(!receiveInApp)}
        />
      </Label>
      <Label>
        Receive Edit Notifications:
        <Input
          type="checkbox"
          checked={receiveEdit}
          onChange={() => setReceiveEdit(!receiveEdit)}
        />
      </Label>
      <Label>
        Receive Time Off Notifications:
        <Input
          type="checkbox"
          checked={receiveTimeOff}
          onChange={() => setReceiveTimeOff(!receiveTimeOff)}
        />
      </Label>
      <Button onClick={handleSavePreferences}>Save Preferences</Button>
    </PreferencesWrapper>
  );
};

export default NotificationPreferences;

const PreferencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
`;

const Label = styled.label`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const Input = styled.input`
  margin-left: 10px;
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: ${colors.primary_500};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primary_500};
  }
`;
