import React from 'react';
import {
  ContentWrapper,
  LayoutContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { Chat } from '../../../chat/chat';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  Heading,
  HeadingContainer,
  SurveyBtn,
} from './survey-dashboard.styled';
import { InfoCharts } from './components/info-charts/info-charts';
import { Surveys } from './components/surveys/surveys';
import { NavigateFunction } from 'react-router-dom';

interface SurveyComponentProps {
  navigate: NavigateFunction;
  // eslint-disable-next-line
  user: any;
}

export const SurveyDashboard: React.FC<SurveyComponentProps> = ({
  user,
  navigate,
}) => {
  const height = useWindowHeight();
  const { name } = user;

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={user} selectedOption={''} navigate={navigate} />
        <ContentWrapper>
          <HeadingContainer>
            <Heading>Survey</Heading>
            <SurveyBtn
              onClick={() => navigate && navigate(`/${name}/survey/create`)}
            >
              + Create Survey
            </SurveyBtn>
          </HeadingContainer>
          <InfoCharts />
          <Surveys user={user} navigate={navigate} />
        </ContentWrapper>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
