import axios from 'axios';
import { SubscriptionPlan } from '../components/pages/super-admin/subscription-plans/interface';
import { apiRoute } from '../constants/api-constants';

export const loadSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
  try {
    const response = await axios.get(
      `${apiRoute}/subscription-plan/all-active`
    );
    console.log('Subscription plans response:', response.data);

    return response.data;
  } catch (err) {
    console.error('Error fetching subscription plans:', err);
    throw new Error('Error fetching subscription plans');
  }
};
