import styled from 'styled-components';
import { colors } from '../../../../style-utils/colors';
import { font } from '../../../../style-utils/text';

export const ApplicantsContentWrapper = styled.div`
  padding: 10px 20px;
  /* margin-bottom: -20px; */
  background: ${colors.creme_100};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const JobSelectMenuDiv = styled.div<{ isFilterJobSelect?: boolean }>`
  margin-bottom: 20px;
  position: ${({ isFilterJobSelect }) => isFilterJobSelect && 'relative'};
`;

export const TableContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  overflow-y: scroll;

  /* WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;
`;

export const StyledTable = styled.table`
  //radi ova shto e zakomentirano 100% sidebarot e smalen
  /* width: 100%; */
  /* border-collapse: collapse; */
  table-layout: fixed;

  @media (max-width: 600px) {
    border: 0;
  }
`;

export const StyledThead = styled.thead`
  @media (max-width: 600px) {
    display: none; // Hide header on small screens
  }
`;
export const StyledSecondThead = styled.thead`
  @media (max-width: 600px) {
    display: none; // Hide header on small screens
  }
`;

export const StyledTr = styled.tr`
  @media (max-width: 600px) {
    display: block;
    margin-bottom: 0.625em;
  }
`;

export const StyledFirstTh = styled.th<{
  columnWidth?: string;
  backgroundColor?: string;
}>`
  padding: 7px 1em;
  text-align: center;
  background-color: teal;
  max-height: 32px;
  color: white;
  min-width: ${({ columnWidth }) => columnWidth && columnWidth};
  border-radius: 4px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'white'};
  font-size: 16px;
`;

export const StyledTh = styled.th<{
  columnWidth?: string;
  backgroundColor?: string;
}>`
  /* box-sizing: border-box; */
  /* padding: 10px 1em; */
  text-align: center;
  height: 54px;
  padding: 0 10px;
  background-color: teal;
  color: white;
  min-width: ${({ columnWidth }) => columnWidth && columnWidth};
  border-radius: 4px;
  font-size: 16px;
  background: ${({ backgroundColor }) =>
    backgroundColor ? `${backgroundColor}` : 'white'};
`;

export const StyledTd = styled.td<{
  interviewIsPending?: boolean;
  interviewIsConfirmed?: boolean;
  interviewIsRejected?: boolean;
  isApplicantStatusSelect?: boolean;
}>`
  padding: 0 1em;
  border-radius: 8px;
  background: white;
  min-height: 70px;

  /* color: ${({ interviewIsPending }) => interviewIsPending && '#fcba03'};
  color: ${({ interviewIsConfirmed }) => interviewIsConfirmed && '#33b507'};
  color: ${({ interviewIsRejected }) =>
    interviewIsRejected && `${colors.error}`}; */

  color: ${({ interviewIsConfirmed }) =>
    interviewIsConfirmed && `${colors.secondary_500}`};

  display: ${({ isApplicantStatusSelect }) =>
    isApplicantStatusSelect && 'flex'};
  justify-content: center;
  align-items: center;
  position: ${({ isApplicantStatusSelect }) =>
    isApplicantStatusSelect && 'relative'};
  font-size: 14px;
  height: 110px;
  text-align: center;

  @media (max-width: 600px) {
    display: block;
    text-align: right;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
  }
`;

export const StyledSelectMenu = styled.select<{
  isModalSelect?: boolean;
  isApplicantStatusSelect?: boolean;
  isFilterJobSelect?: boolean;
}>`
  -webkit-appearance: none;
  width: ${({ isModalSelect }) => isModalSelect && '280px'};
  width: ${({ isApplicantStatusSelect }) => isApplicantStatusSelect && '200px'};
  width: ${({ isFilterJobSelect }) => isFilterJobSelect && '280px'};
  display: flex;
  padding: 14px 20px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  border: ${({ isFilterJobSelect }) =>
    isFilterJobSelect && ` 2px solid ${colors.primary_500}`};
  background: ${colors.white};
  background: ${({ isApplicantStatusSelect }) =>
    isApplicantStatusSelect && `${colors.secondary_50}`};

  font-family: ${font.family};
  color: ${colors.black};
  color: ${({ isFilterJobSelect }) =>
    isFilterJobSelect && `${colors.primary_800}`};
  cursor: pointer;
  position: ${({ isFilterJobSelect }) => isFilterJobSelect && 'relative'};

  a .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

export const TitleButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DocsContainer = styled.button`
  background: none;
  color: #000000;
  width: 130px;
  height: 27px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

export const ButtonContainer = styled.button<{
  mt?: number;
  mb?: number;
  backgroundColor?: string;
  opacity?: string;
  isAIAnalysisButton?: boolean;
  isAIAnalysisPage?: boolean;
}>`
  background: ${({ backgroundColor, opacity }) =>
    backgroundColor && !opacity
      ? backgroundColor
      : opacity
      ? ` rgba(46,119,184, ${opacity})`
      : `${colors.secondary_500}`};

  width: 144px;
  height: 25px;
  height: ${({ isAIAnalysisButton }) => isAIAnalysisButton && '33px'};
  width: ${({ isAIAnalysisButton }) => isAIAnalysisButton && '100px'};
  width: ${({ isAIAnalysisPage }) => isAIAnalysisPage && '160px'};
  border: none;
  transition: all 0.3s ease-out;
  padding: 14px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : `0px`)};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : `0px`)};
  /* box-shadow: 2px 2px 8px 0px #0000001a; */

  &:hover {
    transform: scale(1.01);
    // background-color: ${colors.active};
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
`;

export const ButtonTitle = styled.p<{ fontColor?: string }>`
  font-family: ${font.family};
  font-size: 14px;
  font-weight: 600;
  color: ${({ fontColor }) => fontColor && fontColor};
`;

export const ApplicantStatusModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(15, 23, 42, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
`;

export const ApplicantStatusModal = styled.div<{ small?: boolean }>`
  background: #fff;
  width: ${({ small }) => (small ? '300px' : '500px')};
  /* height: ${({ small }) => (small ? '250px' : '480px')}; */
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ small }) => small && 'center'};
  align-items: ${({ small }) => small && 'center'};
  border-radius: 8px;
  z-index: 10;
`;

export const ModalButtonsDiv = styled.div<{ small?: boolean }>`
  width: 70%;
  justify-content: ${({ small }) => small && 'center'};
  margin: ${({ small }) => small && 'auto'};
  margin-top: 40px;
`;

export const CancelConfirmButton = styled.div<{
  isConfirm?: boolean;
  isSaveComment?: boolean;
}>`
  background: ${({ isConfirm }) =>
    isConfirm ? `${colors.secondary_500}` : `${colors.white}`};
  color: ${({ isConfirm }) => isConfirm && `${colors.white}`};
  display: flex;
  height: 41px;
  height: ${({ isSaveComment }) => isSaveComment && '24px'};
  width: ${({ isSaveComment }) => isSaveComment && '60px'};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
`;

export const UpdateStatusInput = styled.div`
  padding: 12px 24px;
  background: ${colors.white};
  border-radius: 8px;
  gap: 4px;
`;

export const StyledStatusInput = styled.input<{ customSkill?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 96%;
  border: none;
  padding: 14px 16px;
  background: ${colors.dashboard_light_grey};
  font-family: ${font.family};
  width: ${({ customSkill }) => customSkill && '200px'};
  margin-top: ${({ customSkill }) => customSkill && '10px'};

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: ${colors.dashboard_light_grey};
  }
`;

export const TimePickersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const TimePickerSelectMenu = styled.select`
  /* -webkit-appearance: none; */
  display: flex;
  padding: 6px 10px;
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  margin-right: 6px;

  .items &:hover {
    border: 1px solid #5c5d5d;
    border: 1px solid ${colors.purple_dark};
  }

  &:focus {
    outline: none;
    background: ${colors.white};
  }
`;

// export const StyledCommentInput = styled.input<{ disabledStyle?: boolean }>`
//   display: flex;
//   align-items: center;
//   border-radius: 8px;
//   width: 90%;
//   border: none;
//   padding: 14px 16px;
//   background: ${({ disabledStyle }) =>
//     !disabledStyle ? `${colors.white}` : `${colors.dashboard_light_grey}`};
//   border: ${({ disabledStyle }) =>
//     !disabledStyle ? `1px solid ${colors.grey_light}` : 'none'};

//   font-family: ${font.family};

//   ::placeholder {
//     color: ${colors.grey_light};
//   }

//   &:hover {
//     outline: none;
//   }

//   &:focus {
//     outline: none;
//     background: ${colors.white};
//   }
// `;

export const StyledCommentInput = styled.textarea<{ disabledStyle?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  width: 90%;
  border: none;
  padding: 8px 10px;
  background: ${({ disabledStyle }) =>
    !disabledStyle ? `${colors.white}` : `${colors.dashboard_light_grey}`};

  background: ${colors.primary_50};
  border: ${({ disabledStyle }) =>
    !disabledStyle ? `1px solid ${colors.grey_light}` : 'none'};
  font-family: ${font.family};
  min-height: 50px;

  ::placeholder {
    color: ${colors.grey_light};
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: ${colors.white};
    /* background: ${colors.primary_50}; */
  }
`;

export const StyledSelectInterviewType = styled.div`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  font-size: 14px;
  z-index: 100;
`;

export const SelectInterviewTypeMenu = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  border: 0.2px solid ${colors.grey_light};
  background: ${colors.white};
  font-family: ${font.family};
  font-size: 14px;
  position: absolute;
  top: 0;
  z-index: 10;
`;

export const DownArrowContainer = styled.div<{ isJobSelectionMenu?: boolean }>`
  position: absolute;
  top: ${({ isJobSelectionMenu }) => (isJobSelectionMenu ? '84px' : '44px')};
  right: ${({ isJobSelectionMenu }) => (isJobSelectionMenu ? '' : '40px')};
  left: ${({ isJobSelectionMenu }) => isJobSelectionMenu && '250px'};
`;

export const AiAnalysisButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AiAnalysisRegenerateAbsoluteBtn = styled.button`
  box-sizing: border-box;
  size: fit-content;
  margin-top: 5px;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
`;

export const StyledSelectJobTitleAndImgWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const StyledArrowJobTitleWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 30px;
`;

export const StyledArrowIconWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 24px;
  height: 24px;
`;

export const StyledJobTitlesSelectWrapper = styled.div`
  box-sizing: border-box;
  width: 180px;
  overflow: hidden;
  border-radius: 8px;
  background-color: white;
  z-index: 999;
  position: absolute;
  top: 70px;
  left: -14px;
  padding: 3px;
  border-color: rgb(202, 202, 199);
  border-width: 1px;
  border-style: solid;
`;

export const StyledJobTitlesSelectContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 140px;
  flex-direction: column;
  padding: 16px;
  overflow-y: scroll;
  scrollbar-width: none;
  /* WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;
`;

export const StyledJobTitleOption = styled.div`
  padding: 8px 0;
  cursor: pointer;
`;

export const ArrowIconContainer = styled.div<{ rotate?: boolean }>`
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  background-color: ${colors.primary_500};
  border-radius: 50%;
  ${({ rotate }) => rotate && `rotate: 180deg;`};
`;

export const StyledApplicantsViewCommentsBtn = styled.button<{
  haveComments: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ haveComments }) =>
    haveComments ? `${colors.primary_500}` : `${colors.secondary_700}`};
  padding: 8px 16px;
  margin: 0 auto;
  border-style: none;
  border-radius: 24px;
  font-family: ${font.family};
  font-size: 14;
  font-weight: 400;
  color: ${colors.base_white};
  cursor: pointer;
`;
