import React, { useEffect, useRef, useState } from 'react';
import {
  EmployeeAttachButton,
  EmployeeDetailHeader,
  EmployeeDetailsWrapper,
  EmployeeTileOffApplicationButton,
  StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper,
} from '../employee.styled';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { PlusIcon } from '../../../icons/plus-icon-small';
import { EmployeeEducationDetailsData } from '../../../../interfaces/employee-interfaces';
import MessagePopup from '../../../messages/message-popup/message-popup';
import {
  StyledEducationDetailCircle,
  StyledEducationDetailLeftItemWrapper,
  StyledEducationDetailWrapper,
  StyledEducationRightSideItemEducationDetailsWrapper,
  StyledEducationRightSideItemEducationRangeWrapper,
  StyledEducationRightSideItemWrapper,
  StyledEmployeeEducationTabDetailsWrapper,
  StyledImageAndTitleWrapper,
  StyledTitleImage,
  StyledVerticalLine,
} from './employee-education-tab.styled';
import EmployeeEducationEetailsTitleIcon from '../../../icons/employee_education_details_title_icon';
import AddEmployeeEducation from './Add-employee-education/add-employee-education';
import { NewEmployeeDataTypes } from '../add-employee';
import axios from 'axios';
import { apiRoute } from '../../../../constants/api-constants';

// const employeeEducationDetailsData: EmployeeEducationDetailsData[] = [
//   {
//     id: '1',
//     type: "Bachelor's Degree",
//     institution_name: 'Harvard University',
//     duration: '2010-2014',
//   },
//   {
//     id: '2',
//     type: "Master's Degree",
//     institution_name: 'Stanford University',
//     duration: '2015-2017',
//   },
//   {
//     id: '3',
//     type: 'PhD',
//     institution_name: 'MIT',
//     duration: '2018-2022',
//   },
//   {
//     id: '4',
//     type: 'Certificate in Data Science',
//     institution_name: 'Udemy',
//     duration: '2021',
//   },
// ];

interface EmployeeEducationTabProps {
  employee: NewEmployeeDataTypes;
}

const EmployeeEducationTab: React.FC<EmployeeEducationTabProps> = ({
  employee,
}) => {
  const [addNewEducationDetail, setAddNewEducationDetail] =
    useState<boolean>(false);
  const [educationDetails, setEducationDetails] = useState<
    EmployeeEducationDetailsData[]
  >([]);
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [maxWidth, setMaxWidth] = useState(0);
  const rangeRefs = useRef<(HTMLDivElement | null)[]>([]);

  const fetchEmployeeEducationDetails = () => {
    axios
      .get(`${apiRoute}/employee/${employee.id}/education`)
      .then((response) => {
        setEducationDetails(response.data);
      })
      .catch((error) => {
        console.error('Error fetching education details:', error);
        setPopUp({ text: 'Failed to load education details', type: 'error' });
      });
  };

  useEffect(() => {
    fetchEmployeeEducationDetails();
  }, [employee.id]);

  useEffect(() => {
    if (rangeRefs.current.length > 0) {
      const widths = rangeRefs.current.map((ref) => ref?.offsetWidth || 0);
      const widest = Math.max(...widths);
      setMaxWidth(widest);
    }
  }, [educationDetails]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  return (
    <EmployeeDetailsWrapper>
      {educationDetails.length === 0 && (
        <EmployeeDetailHeader>
          <Text
            fontFamily={font.family}
            margin="0"
            color={colors.base_black}
            fontSize={24}
            fontWeight={700}
            lineHeight="28.8px"
          >
            No Education Details
          </Text>
          <StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
            <EmployeeTileOffApplicationButton
              onClick={() => setAddNewEducationDetail(true)}
            >
              <PlusIcon />
              Add New
            </EmployeeTileOffApplicationButton>
          </StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
        </EmployeeDetailHeader>
      )}
      {educationDetails.length > 0 && (
        <StyledEmployeeEducationTabDetailsWrapper>
          <EmployeeDetailHeader>
            <StyledImageAndTitleWrapper>
              <StyledTitleImage>
                <EmployeeEducationEetailsTitleIcon />
              </StyledTitleImage>
              <Text
                fontFamily={font.family}
                margin="0"
                color={colors.primary_500}
                fontSize={40}
                fontWeight={700}
                lineHeight="60px"
              >
                Education
              </Text>
            </StyledImageAndTitleWrapper>
            <StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
              <EmployeeAttachButton
                onClick={() => setAddNewEducationDetail(true)}
              >
                <PlusIcon />
                Add New
              </EmployeeAttachButton>
            </StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper>
          </EmployeeDetailHeader>
          {educationDetails.map((educationDetail, index) => (
            <StyledEducationDetailWrapper key={educationDetail.id}>
              <StyledEducationDetailLeftItemWrapper>
                <StyledVerticalLine />
                <StyledEducationDetailCircle />
              </StyledEducationDetailLeftItemWrapper>
              <StyledEducationRightSideItemWrapper>
                <StyledEducationRightSideItemEducationRangeWrapper
                  ref={(el) => (rangeRefs.current[index] = el)}
                  style={{ width: maxWidth ? `${maxWidth}px` : 'auto' }}
                >
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_black}
                    fontSize={20}
                    fontWeight={300}
                    lineHeight="48px"
                  >
                    {educationDetail.duration}
                  </Text>
                </StyledEducationRightSideItemEducationRangeWrapper>
                <StyledEducationRightSideItemEducationDetailsWrapper>
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.primary_500}
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="6px"
                  >
                    {educationDetail.type}
                  </Text>
                  <Text
                    fontFamily={font.family}
                    margin="0"
                    color={colors.base_black}
                    fontSize={20}
                    fontWeight={700}
                    lineHeight="24px"
                  >
                    {educationDetail.institution_name}
                  </Text>
                </StyledEducationRightSideItemEducationDetailsWrapper>
              </StyledEducationRightSideItemWrapper>
            </StyledEducationDetailWrapper>
          ))}
        </StyledEmployeeEducationTabDetailsWrapper>
      )}
      {addNewEducationDetail && (
        <AddEmployeeEducation
          setAddNewEducationDetail={setAddNewEducationDetail}
          employee={employee}
          setPopUp={setPopUp}
          fetchEmployeeEducationDetails={fetchEmployeeEducationDetails}
        />
      )}
      {popUp && <MessagePopup text={popUp.text} type={popUp.type} />}
    </EmployeeDetailsWrapper>
  );
};

export default EmployeeEducationTab;
