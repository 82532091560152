import axios from 'axios';
import Cookies from 'universal-cookie';
import { apiRoute } from '../constants/api-constants';
import { NavigateFunction } from 'react-router-dom';

const cookies = new Cookies();

export const logOut = async (
  // eslint-disable-next-line
  setUser: (user: any) => void,
  navigate?: NavigateFunction
) => {
  try {
    await axios.post(`${apiRoute}/logOut` as string);

    const cookiePromises = document.cookie.split(';').map((cookie) => {
      const cookieName = cookie.trim().split('=')[0];
      if (cookieName.startsWith('authorization_token')) {
        return cookies.remove(cookieName, { path: '/' });
      }

      return Promise.resolve();
    });

    await Promise.all(cookiePromises);

    localStorage.removeItem('authorization_token');
    sessionStorage.removeItem('authorization_token');

    setUser(null);

    const remainingCookies = document.cookie.split(';').map((cookie) => {
      const cookieName = cookie.trim().split('=')[0];
      if (cookieName.startsWith('authorization_token')) {
        return cookies.remove(cookieName, { path: '/' });
      }

      return Promise.resolve();
    });

    await Promise.all(remainingCookies);

    setUser(null);
    setTimeout(() => {
      if (navigate) {
        navigate('/login');
      } else {
        window.location.href = '/login';
      }
      window.location.reload();
    }, 0);
  } catch (error) {
    console.error('Logout failed:', error);
  }
};
