import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  CompanyLogo,
  StyledCandidateExperienceLeftColumnWrapper,
  StyledCandidateExperienceRightColumnWrapper,
  StyledCandidateExperienceWrapper,
  StyledCategoryContent,
  StyledCategoryContentCopmanyDetails,
  StyledCategoryContentCopmanyNameAndPosition,
  StyledCategoryContentListItem,
  StyledCategoryContentListItemsWrapper,
  StyledCategoryTitle,
  StyledCategoryWrapper,
  StyledCompanyCVComponentAbsoluteButtonsWrapper,
  StyledCompanyCVComponentMainWrapper,
  StyledCompanyCVComponentWrapper,
  StyledFooterWrapper,
  StyledImageAndPersonalInfoWrapper,
  StyledPersonalInfoWrapper,
  StyledProfilePicture,
} from './company-cv-component.styled';
import { Text, font } from '../../../../../style-utils/text';
import { colors } from '../../../../../style-utils/colors';
import {
  StyledCompanyCVEditAndExportBtnWrapper,
  StyledExportEditBackBtn,
} from '../../company-cv.styled';
import { ShareIcon } from '../../../../icons/upload';
import { EditIcon } from '../../../../icons/edit-icon-smaller';
import { Company } from '../../../../app-router/component-props';
import { EmployeeCVData } from '../../hooks/employee-company-cv-data-types';

interface CompanyCvComponentProps {
  company: Company;
  employeeCVData: EmployeeCVData;
  setEdit: Dispatch<SetStateAction<boolean>>;
  newProfilePicture: File | null;
}

const CompanyCvComponent: React.FC<CompanyCvComponentProps> = ({
  company,
  employeeCVData,
  setEdit,
  newProfilePicture,
}) => {
  const [profilePictureURL, setProfilePictureURL] = useState<string | null>(
    null
  );
  const [companyLogoURL, setCompanyLogoURL] = useState<string | null>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (newProfilePicture) {
      setProfilePictureURL(URL.createObjectURL(newProfilePicture));
    } else if (employeeCVData.profilePicture) {
      fetchImageAsBlob(employeeCVData.profilePicture, setProfilePictureURL);
    }

    if (company.profile_picture) {
      fetchImageAsBlob(company.profile_picture, setCompanyLogoURL);
    }
  }, [
    newProfilePicture,
    employeeCVData.profilePicture,
    company.profile_picture,
  ]);

  const fetchImageAsBlob = (
    imageURL: string,
    setImageURL: Dispatch<SetStateAction<string | null>>
  ) => {
    fetch(imageURL)
      .then((response) => response.blob())
      .then((blob) => setImageURL(URL.createObjectURL(blob)))
      .catch((error) => console.error('Error fetching image:', error));
  };

  const preloadImages = (element: HTMLElement): Promise<void[]> => {
    const images = element.getElementsByTagName('img');
    const promises: Promise<void>[] = [];

    for (const img of images) {
      if (!img.complete) {
        promises.push(
          new Promise((resolve) => {
            img.onload = () => resolve();
            img.onerror = () => resolve();
          })
        );
      }
    }

    return Promise.all(promises);
  };

  const generatePDF = () => {
    const input = componentRef.current;

    if (input) {
      preloadImages(input).then(() => {
        html2canvas(input, { useCORS: true, scale: 2 })
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('Company-CV.pdf');
          })
          .catch((error) => {
            console.error('Error generating PDF:', error);
          });
      });
    } else {
      console.error('Component is not mounted or available');
    }
  };

  return (
    <StyledCompanyCVComponentMainWrapper>
      <StyledCompanyCVComponentAbsoluteButtonsWrapper>
        <StyledCompanyCVEditAndExportBtnWrapper>
          <StyledExportEditBackBtn onClick={generatePDF}>
            <Text
              fontFamily={font.family}
              fontSize={16}
              fontWeight={400}
              color={colors.neutral_900}
              margin="0"
            >
              Export
            </Text>
            <ShareIcon />
          </StyledExportEditBackBtn>
          <StyledExportEditBackBtn onClick={() => setEdit(true)}>
            <Text
              fontFamily={font.family}
              fontSize={16}
              fontWeight={400}
              color={colors.neutral_900}
              margin="0"
            >
              Edit
            </Text>
            <EditIcon />
          </StyledExportEditBackBtn>
        </StyledCompanyCVEditAndExportBtnWrapper>
      </StyledCompanyCVComponentAbsoluteButtonsWrapper>
      <StyledCompanyCVComponentWrapper ref={componentRef}>
        <StyledImageAndPersonalInfoWrapper>
          {profilePictureURL && (
            <StyledProfilePicture src={profilePictureURL} />
          )}
          <StyledPersonalInfoWrapper>
            {companyLogoURL && (
              <CompanyLogo src={companyLogoURL} alt="Company logo" />
            )}
            <Text
              fontFamily={font.family}
              fontSize={24}
              fontWeight={700}
              color={colors.neutral_900}
              margin="0"
            >
              {employeeCVData?.name}
            </Text>
            <Text
              fontFamily={font.family}
              fontSize={18}
              fontWeight={700}
              color={colors.secondary_500}
              margin="0"
            >
              {employeeCVData?.jobTitle}
            </Text>
          </StyledPersonalInfoWrapper>
        </StyledImageAndPersonalInfoWrapper>
        <StyledCandidateExperienceWrapper>
          <StyledCandidateExperienceLeftColumnWrapper>
            {/* Professional Summary */}
            <StyledCategoryWrapper>
              <StyledCategoryTitle>Professional Summary:</StyledCategoryTitle>
              <StyledCategoryContent>
                {employeeCVData?.professionalSummary}
              </StyledCategoryContent>
            </StyledCategoryWrapper>
            {/* Professional Summary END*/}
            {/* Work Experience */}
            <StyledCategoryWrapper>
              <StyledCategoryTitle>Work Experience</StyledCategoryTitle>
              {employeeCVData?.workExperience.map((experience, index) => (
                <StyledCategoryContent key={index}>
                  <StyledCategoryContentCopmanyDetails>
                    <StyledCategoryContentCopmanyNameAndPosition>
                      <Text
                        fontFamily={font.family}
                        fontSize={16}
                        fontWeight={700}
                        color={colors.secondary_600}
                        margin="0"
                      >
                        {experience.company}
                      </Text>
                      <Text
                        fontFamily={font.family}
                        fontSize={16}
                        fontWeight={400}
                        color={colors.base_black}
                        margin="0"
                      >
                        {experience.role}
                      </Text>
                    </StyledCategoryContentCopmanyNameAndPosition>
                    <Text
                      fontFamily={font.family}
                      fontSize={12}
                      fontWeight={400}
                      color={colors.base_black}
                      margin="0"
                    >
                      {experience.dateRange}
                    </Text>
                  </StyledCategoryContentCopmanyDetails>
                  <StyledCategoryContentListItemsWrapper>
                    {experience.responsibilities.map(
                      (responsibility, index) => (
                        <StyledCategoryContentListItem key={index}>
                          {responsibility}
                        </StyledCategoryContentListItem>
                      )
                    )}
                  </StyledCategoryContentListItemsWrapper>
                </StyledCategoryContent>
              ))}
            </StyledCategoryWrapper>
            {/* Work Experience END*/}
            {/* Education */}
            <StyledCategoryWrapper>
              <StyledCategoryTitle>Education</StyledCategoryTitle>
              {employeeCVData?.education.map((education, index) => (
                <StyledCategoryContent key={index}>
                  <StyledCategoryContentCopmanyDetails>
                    <StyledCategoryContentCopmanyNameAndPosition>
                      <Text
                        fontFamily={font.family}
                        fontSize={16}
                        fontWeight={700}
                        color={colors.secondary_600}
                        margin="0"
                      >
                        {education.institution}
                      </Text>
                      <Text
                        fontFamily={font.family}
                        fontSize={16}
                        fontWeight={400}
                        color={colors.base_black}
                        margin="0"
                      >
                        {education.degree}
                      </Text>
                    </StyledCategoryContentCopmanyNameAndPosition>
                    <Text
                      fontFamily={font.family}
                      fontSize={12}
                      fontWeight={400}
                      color={colors.base_black}
                      margin="0"
                    >
                      {education.dateRange}
                    </Text>
                  </StyledCategoryContentCopmanyDetails>
                  <StyledCategoryContentListItemsWrapper>
                    {education.details.map((detail, index) => (
                      <StyledCategoryContentListItem key={index}>
                        {detail}
                      </StyledCategoryContentListItem>
                    ))}
                  </StyledCategoryContentListItemsWrapper>
                </StyledCategoryContent>
              ))}
            </StyledCategoryWrapper>
            {/* Education END*/}
          </StyledCandidateExperienceLeftColumnWrapper>
          <StyledCandidateExperienceRightColumnWrapper>
            {/* Skills */}
            <StyledCategoryWrapper>
              <StyledCategoryTitle>Skills</StyledCategoryTitle>
              <StyledCategoryContentListItemsWrapper>
                {employeeCVData?.skills.map((skill, index) => (
                  <StyledCategoryContentListItem key={index}>
                    {skill}
                  </StyledCategoryContentListItem>
                ))}
              </StyledCategoryContentListItemsWrapper>
            </StyledCategoryWrapper>
            {/* Skills END*/}
            {/* Tools */}
            <StyledCategoryWrapper>
              <StyledCategoryTitle>Tools</StyledCategoryTitle>
              <StyledCategoryContentListItemsWrapper>
                {employeeCVData?.tools.map((tool, index) => (
                  <StyledCategoryContentListItem key={index}>
                    {tool}
                  </StyledCategoryContentListItem>
                ))}
              </StyledCategoryContentListItemsWrapper>
            </StyledCategoryWrapper>
            {/* Tools END*/}
          </StyledCandidateExperienceRightColumnWrapper>
        </StyledCandidateExperienceWrapper>
        <StyledFooterWrapper>
          <Text
            fontFamily={font.family}
            fontSize={14}
            fontWeight={400}
            color={colors.neutral_700}
            margin="0"
          >
            {company.name}
          </Text>
          <Text
            fontFamily={font.family}
            fontSize={14}
            fontWeight={400}
            color={colors.neutral_700}
            margin="0"
          >
            {company.email}
          </Text>
        </StyledFooterWrapper>
      </StyledCompanyCVComponentWrapper>
    </StyledCompanyCVComponentMainWrapper>
  );
};

export default CompanyCvComponent;
