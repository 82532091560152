import React, { useEffect, useRef, useState } from 'react';
import {
  Logo,
  DesktopHeaderWrapper,
  Container,
  NotificationAndUserWrapper,
  IconAndTextWrapper,
  TextAndIconWrapper,
  UserDropdown,
  UserDropdownContent,
  MobileHeaderWrapper,
} from './top-menu.styled';
import logo from '../../../assets/images/logo_full_big.png';
import { SearchBar } from '../../search/search';
import { ProfileIcon } from '../../icons/profile';
import { Text, font } from '../../../style-utils/text';
import { useAuth } from '../../../context/auth-provider';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../../../style-utils/colors';
import { DownArrow } from '../../icons/down-arrow';
import { UpArrow } from '../../icons/up-arrow';
import { SettingsGearIcon } from '../../icons/settings-geat-icon';
import { LogoutIconBlack } from '../../icons/logout-icon';
import { NotificationComponent } from './notification-component';
import { logOut } from '../../../function-utils/log-out';
import BigLogo from '../../../assets/images/logo_full_big.png';
interface TopMenuProps {
  hideUserAndNotification?: boolean;
  navigationUrl?: string;
  bigLogo?: boolean;
  isSuperAdmin?: boolean;
  companyLogoUrl?: string;
}

export const TopMenu: React.FC<TopMenuProps> = ({
  hideUserAndNotification = false,
  navigationUrl = '/',
  bigLogo = false,
  isSuperAdmin = false,
  companyLogoUrl,
}) => {
  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const dropdownContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogoClick = () => {
    navigate(navigationUrl);
  };

  const handleToggleDropddown = () => {
    setToggleDropdown(!toggleDropdown);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownContainerRef.current &&
      !dropdownContainerRef.current.contains(event.target as Node)
    ) {
      setToggleDropdown(false);
    }
  };
  const { setUser } = useAuth();

  const location = useLocation();

  const handleClick = () => {
    if (location.pathname.includes('super-admin')) {
      navigate('/admin');
    } else {
      navigate('/super-admin');
    }
  };

  return (
    <>
      {/* MOBILE */}
      <MobileHeaderWrapper>
        <Logo>
          {companyLogoUrl ? (
            <img
              onClick={handleLogoClick}
              style={{
                maxHeight: '30px',
                cursor: 'pointer',
              }}
              src={companyLogoUrl}
              alt="logo"
            />
          ) : bigLogo ? (
            <img
              onClick={handleLogoClick}
              style={{
                maxHeight: '30px',
                cursor: 'pointer',
              }}
              src={BigLogo}
              alt="logo"
            />
          ) : (
            <img
              onClick={handleLogoClick}
              style={{
                maxHeight: '40px',
                cursor: 'pointer',
              }}
              src={logo}
              alt="logo"
            />
          )}
        </Logo>
      </MobileHeaderWrapper>
      {/* MOBILE */}
      {/* DESKTOP */}
      <DesktopHeaderWrapper id="top-menu-container">
        <Logo>
          {companyLogoUrl ? (
            <img
              onClick={handleLogoClick}
              style={{
                maxHeight: '50px',
                cursor: 'pointer',
                margin: 0,
                padding: 0,
              }}
              src={companyLogoUrl}
              alt="logo"
            />
          ) : bigLogo ? (
            <img
              onClick={handleLogoClick}
              style={{
                maxHeight: '40px',
                cursor: 'pointer',
              }}
              src={BigLogo}
              alt="logo"
            />
          ) : (
            <img
              onClick={handleLogoClick}
              style={{
                maxHeight: '50px',
                paddingLeft: '32px',
                cursor: 'pointer',
              }}
              src={logo}
              alt="logo"
            />
          )}
        </Logo>
        <Container>
          {user && user?.entityType !== 'employee' && <SearchBar user={user} />}
          {!hideUserAndNotification && user && (
            <NotificationAndUserWrapper>
              {isSuperAdmin && (
                <div
                  onClick={handleClick}
                  style={{
                    padding: '12px',
                    paddingBottom: '4px',
                    borderBottom: '2px solid red',
                    color: 'red',
                    fontSize: '20px',
                    cursor: 'pointer',
                    display: 'inline-block',
                  }}
                >
                  Super <br></br> Admin
                </div>
              )}
              <NotificationComponent user={user} />
              {user.entityType !== 'employee' && (
                <IconAndTextWrapper
                  ref={dropdownContainerRef}
                  onClick={handleToggleDropddown}
                >
                  <ProfileIcon />
                  <TextAndIconWrapper>
                    <Text fontFamily={font.family} margin="0">
                      {user.name}
                    </Text>
                    {toggleDropdown ? (
                      <UpArrow color="#000000" />
                    ) : (
                      <DownArrow />
                    )}
                  </TextAndIconWrapper>
                  {toggleDropdown && (
                    <UserDropdown>
                      <UserDropdownContent
                        onClick={() => navigate(`/${user.name}/settings`)}
                      >
                        <SettingsGearIcon />
                        <Text
                          margin="0"
                          fontFamily={font.family}
                          color={colors.base_black}
                        >
                          Settings
                        </Text>
                      </UserDropdownContent>
                      <UserDropdownContent
                        onClick={() => logOut(setUser, navigate)}
                      >
                        <LogoutIconBlack />
                        <Text
                          margin="0"
                          fontFamily={font.family}
                          color={colors.base_black}
                        >
                          Logout
                        </Text>
                      </UserDropdownContent>
                    </UserDropdown>
                  )}
                </IconAndTextWrapper>
              )}
            </NotificationAndUserWrapper>
          )}
        </Container>
      </DesktopHeaderWrapper>
      {/* DESKTOP */}
    </>
  );
};
