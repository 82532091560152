import axios from 'axios';
import React, { useState } from 'react';
import { apiRoute } from '../../../constants/api-constants';
import {
  CloseIconWrapper,
  CustomButton,
  CustomInputField,
  EmailsListWrapper,
  FormWrapper,
  InputAndErrorWrapper,
  SharePopupContainer,
  StyledAddedEmail,
  Title,
  ButtonsWrapper,
} from './job-board.styled';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { DeleteButton } from '../jobs/create-job-offer/create-job-offer-flow.styled';
import { CloseIcon } from '../../icons/close-icon';
import { JobOfferWithCompanyData } from '../../../interfaces/job-interfaces';
import { Spinner } from '../../icons/spinner';
import { Company } from '../../app-router/component-props';
import { ColorTemplate } from '../company-job-board/company-job-board';

interface SendInvitationFormProps {
  handleToggleShareForm?: (index: string | null) => void;
  job: JobOfferWithCompanyData | null;
  company?: Company;
  colorTemplate?: ColorTemplate;
}

export const SendInvitationForm: React.FC<SendInvitationFormProps> = ({
  handleToggleShareForm,
  job,
  colorTemplate,
}) => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isValidEmail = (email: string): boolean => {
    return emailRegex.test(email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError('');
  };

  const handleAddEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && email.trim()) {
      e.preventDefault();
      if (isValidEmail(email)) {
        if (!emails.includes(email)) {
          setEmails([...emails, email]);
          setEmail('');
        } else {
          setError('This email is already added');
        }
      } else {
        setError('Invalid email address');
      }
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const handleSendEmailSubmit = async () => {
    if (emails.length === 0) {
      setError('Please add at least one valid email');

      return;
    }

    setIsLoading(true);
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    try {
      await axios.post(
        `${apiRoute}/applicant/send-emails`,
        {
          emails,
          job,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setResponseMessage('Emails sent successfully');
      setEmails([]);
      if (handleToggleShareForm) {
        timeoutId = setTimeout(() => {
          handleToggleShareForm(null);
        }, 500);
      }
    } catch (error) {
      console.error('Error sending emails:', error);
      setResponseMessage('Error sending emails');
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SharePopupContainer backgroundColor={colorTemplate?.background_fill_color}>
      <Title titleColor={colorTemplate?.title_color}>Invite others</Title>
      <FormWrapper onSubmit={(e) => e.preventDefault()}>
        <InputAndErrorWrapper>
          <CustomInputField
            type="text"
            name="email"
            placeholder="Add Email and press Enter"
            value={email}
            onChange={handleChange}
            onKeyDown={handleAddEmail}
          />
        </InputAndErrorWrapper>
        {error && (
          <Text
            fontFamily={font.family}
            fontSize={14}
            color={colors.primary_500}
            margin="0"
            fontWeight={500}
          >
            {error}
          </Text>
        )}
      </FormWrapper>
      {emails.length > 0 && (
        <EmailsListWrapper>
          {emails.map((email, index) => (
            <StyledAddedEmail
              key={index}
              backgroundColor={colorTemplate?.filter_color}
              borderColor="transparent"
            >
              <Text
                fontFamily={font.family}
                color={colorTemplate?.filter_text_color || colors.base_black}
                margin="0"
              >
                {email}
              </Text>
              <DeleteButton onClick={() => handleDeleteEmail(email)}>
                <Text
                  fontFamily={font.family}
                  color={colorTemplate?.filter_text_color || colors.base_black}
                  margin="0"
                >
                  &#10006;
                </Text>
              </DeleteButton>
            </StyledAddedEmail>
          ))}
        </EmailsListWrapper>
      )}
      <ButtonsWrapper>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <CustomButton
              bgColor={colorTemplate?.filter_color || colors.primary_100}
              padding="8px 24px"
              borderRadius="8px"
              color={colorTemplate?.filter_text_color || colors.black}
              type="button"
              onClick={() =>
                handleToggleShareForm && handleToggleShareForm(null)
              }
            >
              Back
            </CustomButton>
            <CustomButton
              bgColor={colorTemplate?.button_color || colors.primary_500}
              padding="8px 24px"
              borderRadius="8px"
              color={colorTemplate?.button_text_color || colors.white}
              type="button"
              onClick={handleSendEmailSubmit}
            >
              Send
            </CustomButton>
          </>
        )}
      </ButtonsWrapper>
      <CloseIconWrapper
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          if (handleToggleShareForm) {
            handleToggleShareForm(null);
          }
        }}
      >
        <CloseIcon />
      </CloseIconWrapper>
      {responseMessage && (
        <Text
          margin="16px 0 0 0"
          fontFamily={font.family}
          fontSize={14}
          color={colorTemplate?.company_title_color || colors.secondary_600}
          fontWeight={500}
        >
          {responseMessage}
        </Text>
      )}
    </SharePopupContainer>
  );
};

export default SendInvitationForm;
