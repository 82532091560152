import React, { useEffect, useState, useRef } from 'react';
import { resizeImage } from '../../../function-utils/image-resizer';
import {
  ChangeEmployeeStatusBtn,
  EditAndChangeEmployeeStatusWrapper,
  EditWrapper,
  EmployeeDetailStyledInput,
  EmployeeDetailStyledLabel,
  EmployeeDetailStyledLabelAndInputWrapper,
  EmployeeHeading,
  EmployeeInfoWrapper,
  EmployeePersonalInfoContainer,
  ListContainer,
  PersonalInfoContainer,
  PersonalInfoWrapper,
  ProfileImageContainer,
  WrapperColumnDiv,
  StyledEmployeeImage,
} from './employee.styled';
import { Text, font } from '../../../style-utils/text';
import { colors } from '../../../style-utils/colors';
import { EditIcon } from '../../icons/edit-icon';
import { NewEmployeeDataTypes } from './add-employee';
import UserDemoImage from '../../../assets/images/img_avatar.png';
import {
  AddEmployeeProfilePictureContainer,
  AddEmployeeStyledButton,
  DownArrowContainer,
  StyledSelect,
} from './add-employee.styled';
import { ErrorLabel, WrapperDiv } from '../jobs/jobs.styled';
import { BriefcaseIcon } from '../../icons/briefcase';
import { DownArrow } from '../../icons/down-arrow';
import { EnvelopeIcon } from '../../icons/envelope';
import { LocationPinIcon } from '../../icons/location-pin';
import { PhoneIcon } from '../../icons/phone';
import axios, { AxiosError } from 'axios';
import { apiRoute } from '../../../constants/api-constants';
import { EmployeeJobData } from '../../../interfaces/employee-job-interfaces';
import MessagePopup from '../../messages/message-popup/message-popup';
import EmployeeChangeEmploymentStatusConfirmation from './employee-change-employment-status-confirmation';
import { GenderIcon } from '../../icons/gender';
import { genders } from '../../../constants/genders';

interface EmployeePersonalInfoTabProps {
  employee: NewEmployeeDataTypes;
  companyJobs: EmployeeJobData[];
  handleToggleEditMode: (toggleFor: string) => void;
  personalDetailsEditMode: boolean;
  employeeFromDB: () => void;
  // eslint-disable-next-line
  company: any;
}

export const EmployeePersonalInfoTab: React.FC<
  EmployeePersonalInfoTabProps
> = ({
  employee,
  companyJobs,
  handleToggleEditMode,
  personalDetailsEditMode,
  employeeFromDB,
  company,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [employeeEditInitialValues, setEmployeeEditInitialValues] =
    useState<NewEmployeeDataTypes>();
  const [newProfilePicture, setNewProfilePicture] = useState<File | null>(null);
  const [removeProfilePicture, setRemoveProfilePicture] = useState('null');
  const [invalidFieldsInputArrayPersonal, setInvalidFieldsInputArrayPersonal] =
    useState<string[]>([]);
  const [wrongPhoneFormat, setWrongPhoneFormat] = useState<boolean>(false);
  const [wrongImageFormat, setWrongImageFormat] = useState<string>('');
  const [popUp, setPopUp] = useState<{
    text: string;
    type: 'success' | 'error';
  } | null>(null);
  const [
    employmentStatusChangeConfirmationVisible,
    setemploymentStatusChangeConfirmationVisible,
  ] = useState<boolean>(false);

  useEffect(() => {
    setEmployeeEditInitialValues(employee);
    setNewProfilePicture(null);
    setInvalidFieldsInputArrayPersonal([]);
  }, [personalDetailsEditMode]);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;

    if (employeeEditInitialValues) {
      if (name === 'phone_number') {
        const numberPattern = /^\+?[\d\s\-()./]+$/;
        if (!numberPattern.test(value)) {
          setWrongPhoneFormat(true);
        } else {
          setWrongPhoneFormat(false);
        }
      }
      setEmployeeEditInitialValues({
        ...employeeEditInitialValues,
        [name]: value,
      });
    }
  };

  const handleSubmitPersonal = async () => {
    const invalidFields = validateNewJobOfferDataPersonal(
      employeeEditInitialValues
    );
    setInvalidFieldsInputArrayPersonal(invalidFields);

    if (invalidFields.length !== 0) {
      return;
    }

    if (employeeEditInitialValues) {
      if (wrongPhoneFormat) return;

      try {
        const formData = new FormData();

        if (newProfilePicture) {
          formData.append('profile_picture', newProfilePicture);
        } else {
          formData.append('profile_picture', '');
        }
        formData.append('remove_profile_picture', removeProfilePicture);

        formData.append('id', employeeEditInitialValues.id);
        formData.append('first_name', employeeEditInitialValues.first_name);
        formData.append('last_name', employeeEditInitialValues.last_name);
        formData.append('email', employeeEditInitialValues.email);
        formData.append('phone_number', employeeEditInitialValues.phone_number);
        formData.append('address', employeeEditInitialValues.address);
        formData.append('job_id', employeeEditInitialValues.job_id);
        formData.append(
          'contract_type',
          employeeEditInitialValues.contract_type
        );
        formData.append(
          'date_of_employment',
          employeeEditInitialValues.date_of_employment
        );
        formData.append(
          'end_of_contract',
          employeeEditInitialValues.end_of_contract
        );
        formData.append('seniority', employeeEditInitialValues.seniority);
        formData.append('salary', employeeEditInitialValues.salary);
        formData.append('gender', employeeEditInitialValues.gender);

        const response = await axios.post(
          `${apiRoute}/edit-employee`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        );

        if (response.status === 200) {
          console.log('Employee successfully edited:', response.data);
        } else {
          console.error('Unexpected response status:', response.status);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError;
          console.error('Axios error:', axiosError.message);
          if (axiosError.response) {
            console.error('Response error:', axiosError.response.data);
          } else if (axiosError.request) {
            console.error('Request error:', axiosError.request);
          }
        } else {
          console.error('Non-Axios error:', error);
        }
      } finally {
        handleToggleEditMode('personal');
      }
    }
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateNewJobOfferDataPersonal = (data: any) => {
    const requiredFields = [
      'first_name',
      'last_name',
      'email',
      'phone_number',
      'address',
    ];
    const emptyFields: string[] = [];

    if (wrongPhoneFormat) {
      emptyFields.push('phone_number');
    }

    requiredFields.forEach((field) => {
      if (!data[field] || data[field].trim().length === 0) {
        emptyFields.push(field);
      }
    });

    return emptyFields;
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (popUp) {
      timer = setTimeout(() => {
        setPopUp(null);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [popUp]);

  const closeEmployeeChangeEmploymentStatusConfirmation = () => {
    setemploymentStatusChangeConfirmationVisible(false);
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
    console.log('NEW PROFILE PIC', newProfilePicture);
    console.log('PROFILE PIC EMPLOYEE', employee.profile_picture);
  };

  return (
    <EmployeeInfoWrapper>
      <EditAndChangeEmployeeStatusWrapper>
        {company.entityType !== 'employee' && (
          <ChangeEmployeeStatusBtn
            onClick={() => {
              setemploymentStatusChangeConfirmationVisible(true);
            }}
          >
            <Text
              fontSize={16}
              mt={0}
              mb={0}
              fontWeight={700}
              color={colors.base_white}
              fontFamily={font.family}
            >
              {employee.status === 'active' ? 'End Employment' : 'Rehire'}
            </Text>
          </ChangeEmployeeStatusBtn>
        )}
        <EditWrapper
          onClick={() => {
            handleToggleEditMode('personal');
          }}
        >
          <Text
            fontWeight={700}
            color={colors.base_black}
            fontFamily={font.family}
          >
            Edit
          </Text>
          <EditIcon />
        </EditWrapper>
      </EditAndChangeEmployeeStatusWrapper>
      <EmployeePersonalInfoContainer>
        {!personalDetailsEditMode && (
          <ProfileImageContainer>
            {employee && employee.profile_picture ? (
              <img
                src={employee.profile_picture as string}
                alt="profile picture"
              />
            ) : (
              <img src={UserDemoImage} alt="profile picture placeholder" />
            )}
          </ProfileImageContainer>
        )}
        {personalDetailsEditMode && (
          <WrapperColumnDiv>
            <Text
              margin="0"
              fontSize={18}
              fontWeight={700}
              fontFamily={font.family}
              color={colors.base_black}
              lineHeight="21.6px"
              align="center"
            >
              Select or Drop your image here
            </Text>
            <AddEmployeeProfilePictureContainer
              onDrop={(e) => {
                e.preventDefault();
                const file = e.dataTransfer.files[0];
                if (file && file.type.startsWith('image/')) {
                  setWrongImageFormat('');
                  setNewProfilePicture(file); // Set the new profile picture here
                } else {
                  console.error(
                    'Invalid file type. Please drop an image file.'
                  );
                  setWrongImageFormat(
                    'Invalid file type. Please drop an image file.'
                  );
                }
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <StyledEmployeeImage
                onClick={handleImageClick}
                src={
                  newProfilePicture instanceof File
                    ? URL.createObjectURL(newProfilePicture)
                    : (employeeEditInitialValues?.profile_picture as string) ||
                      UserDemoImage
                }
              />
            </AddEmployeeProfilePictureContainer>
            {!newProfilePicture &&
            !employeeEditInitialValues?.profile_picture ? (
              <AddEmployeeStyledButton
                bgColor={colors.primary_100}
                color={colors.base_black}
                padding="8px 24px"
                borderRadius="8px"
                width="153px"
                type="button"
                onClick={() => {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'image/*';
                  // Handling the image file selection and resizing
                  // eslint-disable-next-line
                  input.onchange = async (e: any) => {
                    const file = e.target.files[0];
                    if (file) {
                      try {
                        const resizedImage = await resizeImage(file); // Resize the image
                        const resizedFile = new File(
                          [resizedImage],
                          file.name,
                          {
                            type: file.type,
                          }
                        );
                        setNewProfilePicture(resizedFile); //set
                        console.log('Image resized.');
                      } catch (error) {
                        console.error('Image resizing failed:', error);
                      }
                    }
                  };
                  input.click();
                  setRemoveProfilePicture('null');
                }}
              >
                Upload Image
              </AddEmployeeStyledButton>
            ) : (
              <AddEmployeeStyledButton
                bgColor={colors.primary_100}
                color={colors.base_black}
                padding="8px 24px"
                borderRadius="8px"
                width="153px"
                type="button"
                onClick={() => {
                  setNewProfilePicture(null);
                  setEmployeeEditInitialValues((prevValues) => {
                    if (!prevValues) return undefined; // Ensure prevValues is defined

                    return {
                      ...prevValues,
                      profile_picture: null, // Set profile_picture to null
                    };
                  });
                  setRemoveProfilePicture('yes');
                }}
              >
                Remove Image
              </AddEmployeeStyledButton>
            )}
            {wrongImageFormat && <ErrorLabel>{wrongImageFormat}</ErrorLabel>}
          </WrapperColumnDiv>
        )}
        <PersonalInfoContainer>
          {!personalDetailsEditMode && (
            <EmployeeHeading>
              {employee && employee.first_name}&nbsp;
              {employee && employee.last_name}
            </EmployeeHeading>
          )}
          {personalDetailsEditMode && (
            <WrapperDiv
              gap="8px"
              mb={personalDetailsEditMode ? '8px' : '24px'}
            ></WrapperDiv>
          )}
          <PersonalInfoWrapper gap={personalDetailsEditMode ? `8px` : ''}>
            {personalDetailsEditMode && (
              <>
                <EmployeeDetailStyledLabelAndInputWrapper>
                  <EmployeeDetailStyledLabel htmlFor="first_name">
                    First name
                  </EmployeeDetailStyledLabel>
                  <EmployeeDetailStyledInput
                    type="text"
                    placeholder="Enter first name"
                    name="first_name"
                    id="first_name"
                    value={employeeEditInitialValues?.first_name}
                    onChange={handleInputChange}
                  />
                  {invalidFieldsInputArrayPersonal.includes('first_name') && (
                    <ErrorLabel>Please enter first name</ErrorLabel>
                  )}
                </EmployeeDetailStyledLabelAndInputWrapper>
                <EmployeeDetailStyledLabelAndInputWrapper>
                  <EmployeeDetailStyledLabel htmlFor="last_name">
                    Last name
                  </EmployeeDetailStyledLabel>
                  <EmployeeDetailStyledInput
                    type="text"
                    placeholder="Enter last name"
                    name="last_name"
                    id="last_name"
                    value={employeeEditInitialValues?.last_name}
                    onChange={handleInputChange}
                  />
                  {invalidFieldsInputArrayPersonal.includes('last_name') && (
                    <ErrorLabel>Please enter last name</ErrorLabel>
                  )}
                </EmployeeDetailStyledLabelAndInputWrapper>{' '}
              </>
            )}
            {!personalDetailsEditMode && (
              <ListContainer>
                <BriefcaseIcon />
                <Text
                  margin="0"
                  color={colors.neutral_700}
                  fontFamily={font.family}
                >
                  {companyJobs.find((job) => job.id === employee.job_id)
                    ?.job_position || '/'}
                </Text>
              </ListContainer>
            )}
            {personalDetailsEditMode && (
              <EmployeeDetailStyledLabelAndInputWrapper>
                <EmployeeDetailStyledLabel htmlFor="job_position">
                  Job position
                </EmployeeDetailStyledLabel>
                <StyledSelect
                  name="job_id"
                  id="job_position"
                  onChange={handleInputChange}
                >
                  <option
                    value={employeeEditInitialValues?.job_position}
                    selected
                    disabled
                  >
                    {employeeEditInitialValues?.job_position}
                  </option>
                  {companyJobs.map((companyJob) => {
                    return (
                      <option key={companyJob.id} value={companyJob.id}>
                        {companyJob.job_position}
                      </option>
                    );
                  })}
                </StyledSelect>
                <DownArrowContainer right="20px" top="42px">
                  <DownArrow />
                </DownArrowContainer>
              </EmployeeDetailStyledLabelAndInputWrapper>
            )}
            {!personalDetailsEditMode && (
              <ListContainer>
                <EnvelopeIcon />
                <Text
                  margin="0"
                  color={colors.neutral_700}
                  fontFamily={font.family}
                >
                  {employee && employee.email}
                </Text>
              </ListContainer>
            )}
            {personalDetailsEditMode && (
              <EmployeeDetailStyledLabelAndInputWrapper>
                <EmployeeDetailStyledLabel htmlFor="email">
                  Email
                </EmployeeDetailStyledLabel>
                <EmployeeDetailStyledInput
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  id="email"
                  value={employeeEditInitialValues?.email}
                  onChange={handleInputChange}
                />
                {invalidFieldsInputArrayPersonal.includes('email') && (
                  <ErrorLabel>Please enter email</ErrorLabel>
                )}
              </EmployeeDetailStyledLabelAndInputWrapper>
            )}
            {personalDetailsEditMode && (
              <EmployeeDetailStyledLabelAndInputWrapper>
                <EmployeeDetailStyledLabel htmlFor="gender">
                  Gender
                </EmployeeDetailStyledLabel>
                <StyledSelect
                  name="gender"
                  id="gender"
                  onChange={handleInputChange}
                >
                  <option
                    value={employeeEditInitialValues?.gender || ''}
                    selected
                    disabled
                  >
                    {employeeEditInitialValues?.gender &&
                    employeeEditInitialValues?.gender !== 'null'
                      ? employeeEditInitialValues.gender
                      : 'Gender not provided'}
                  </option>
                  {genders.map((gender, index) => {
                    return (
                      <option key={index} value={gender}>
                        {gender}
                      </option>
                    );
                  })}
                </StyledSelect>
                <DownArrowContainer right="20px" top="42px">
                  <DownArrow />
                </DownArrowContainer>
              </EmployeeDetailStyledLabelAndInputWrapper>
            )}

            {!personalDetailsEditMode && (
              <ListContainer>
                <GenderIcon />
                <Text
                  margin="0"
                  color={colors.neutral_700}
                  fontFamily={font.family}
                >
                  {employee &&
                  employee.gender &&
                  employee.gender !== 'null' &&
                  employee.gender !== ''
                    ? employee.gender
                    : 'Gender not provided'}
                </Text>
              </ListContainer>
            )}

            {!personalDetailsEditMode && (
              <ListContainer>
                <LocationPinIcon />
                <Text
                  margin="0"
                  color={colors.neutral_700}
                  fontFamily={font.family}
                >
                  {employee && employee.address ? employee.address : '/'}
                </Text>
              </ListContainer>
            )}
            {personalDetailsEditMode && (
              <EmployeeDetailStyledLabelAndInputWrapper>
                <EmployeeDetailStyledLabel htmlFor="address">
                  Address
                </EmployeeDetailStyledLabel>
                <EmployeeDetailStyledInput
                  type="text"
                  placeholder="Enter address"
                  name="address"
                  id="address"
                  value={employeeEditInitialValues?.address}
                  onChange={handleInputChange}
                />
                {invalidFieldsInputArrayPersonal.includes('address') && (
                  <ErrorLabel>Please enter address</ErrorLabel>
                )}
              </EmployeeDetailStyledLabelAndInputWrapper>
            )}
            {!personalDetailsEditMode && (
              <ListContainer>
                <PhoneIcon />
                <Text
                  margin="0"
                  color={colors.neutral_700}
                  fontFamily={font.family}
                >
                  {employee && employee.phone_number}
                </Text>
              </ListContainer>
            )}
            {personalDetailsEditMode && (
              <EmployeeDetailStyledLabelAndInputWrapper>
                <EmployeeDetailStyledLabel htmlFor="phone_number">
                  Phone number
                </EmployeeDetailStyledLabel>
                <EmployeeDetailStyledInput
                  type="address"
                  placeholder="Enter phone number"
                  name="phone_number"
                  id="phone_number"
                  value={employeeEditInitialValues?.phone_number}
                  onChange={handleInputChange}
                />
                {wrongPhoneFormat && (
                  <ErrorLabel>Please enter only numbers</ErrorLabel>
                )}
              </EmployeeDetailStyledLabelAndInputWrapper>
            )}
          </PersonalInfoWrapper>
          {personalDetailsEditMode && (
            <WrapperDiv justifyContent="end" gap="8px" mt="16px">
              <AddEmployeeStyledButton
                bgColor={colors.primary_400}
                borderRadius="4px"
                padding="8px 40px"
                color={colors.base_white}
                type="button"
                onClick={() => handleToggleEditMode('personal')}
              >
                Cancel
              </AddEmployeeStyledButton>
              <AddEmployeeStyledButton
                bgColor={colors.primary_600}
                borderRadius="4px"
                padding="8px 40px"
                color={colors.base_white}
                type="submit"
                onClick={handleSubmitPersonal}
              >
                Add
              </AddEmployeeStyledButton>
            </WrapperDiv>
          )}
        </PersonalInfoContainer>
      </EmployeePersonalInfoContainer>
      {popUp && <MessagePopup type={popUp.type} text={popUp.text} />}
      {employmentStatusChangeConfirmationVisible && (
        <EmployeeChangeEmploymentStatusConfirmation
          closeEmployeeChangeEmploymentStatusConfirmation={
            closeEmployeeChangeEmploymentStatusConfirmation
          }
          setPopUp={setPopUp}
          employeeFromDB={employeeFromDB}
          employee={employee}
        />
      )}
    </EmployeeInfoWrapper>
  );
};
