import React, { useState } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import {
  LayoutContainer,
  MainContentWrapper,
  NavbarAndContentTransparentContainer,
} from '../../../layout/layout-container.styled';
import { Text, font } from '../../../../style-utils/text';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { Chat } from '../../../chat/chat';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { SkillMapTable } from './components/table/skill-map-table';
import { colors } from '../../../../style-utils/colors';
import { SkillMapFilter } from './components/filter/filter';
import { SkillMapSearch } from './components/search/search';
import {
  ContentWrapperSkills,
  SearchFilterWrapper,
} from './employees-skills-table.styled';

// Sample data for employees and their skill levels
const employees = [
  {
    name: 'John Smith',
    skills: { Beginner: true, Intermediate: false, Advanced: false },
  },
  {
    name: 'Emily Johnson',
    skills: { Beginner: true, Intermediate: false, Advanced: false },
  },
  {
    name: 'Michael Brown',
    skills: { Beginner: false, Intermediate: true, Advanced: false },
  },
  {
    name: 'Sophia Davis',
    skills: { Beginner: true, Intermediate: false, Advanced: false },
  },
  {
    name: 'David Martinez',
    skills: { Beginner: false, Intermediate: true, Advanced: false },
  },
  {
    name: 'Angela Antova',
    skills: { Beginner: false, Intermediate: false, Advanced: true },
  },
  {
    name: 'Andrej Filipovski',
    skills: { Beginner: false, Intermediate: false, Advanced: true },
  },
  {
    name: 'Marko Stefanovski',
    skills: { Beginner: false, Intermediate: true, Advanced: false },
  },
  {
    name: 'Konstantin Stamboliev',
    skills: { Beginner: false, Intermediate: true, Advanced: false },
  },
];

export const EmployeesSkillsTable: React.FC<DefaultComponentProps> = ({
  navigate,
  user,
}) => {
  const height = useWindowHeight();
  const [filteredEmployees, setFilteredEmployees] = useState(employees);

  return (
    <LayoutContainer height={height}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={user} selectedOption={''} navigate={navigate} />
        <ContentWrapperSkills>
          <Text
            fontFamily={font.family}
            fontSize={38}
            fontWeight={700}
            color={colors.base_black}
            margin="0"
          >
            Employee Skill Map
          </Text>
          <NavbarAndContentTransparentContainer>
            <SearchFilterWrapper>
              <SkillMapSearch
                employees={employees}
                setFilteredEmployees={setFilteredEmployees}
              />
              <SkillMapFilter />
            </SearchFilterWrapper>
            <SkillMapTable
              employees={filteredEmployees}
              setFilteredEmployees={setFilteredEmployees}
            />
          </NavbarAndContentTransparentContainer>
        </ContentWrapperSkills>
      </MainContentWrapper>
      <Chat />
    </LayoutContainer>
  );
};
