import React, { useEffect, useState } from 'react';
// import { Profile } from '../../profile/profile';
import {
  ButtonContainer,
  ButtonTitle,
  Dropdown,
  DropDownContainer,
  DropdownLink,
  ItemsContainer,
  LogoutContainer,
  MenuItem,
  NameContainer,
  SidebarContainer,
} from './sidebar.styled';
import { NavigateFunction, useLocation } from 'react-router-dom';
import { Text, font } from '../../../style-utils/text';
import { Company } from '../../app-router/component-props';
import { useWindowHeight } from '../../../style-utils/use-window-height';
import { DownArrow } from '../../icons/down-arrow';
import { UpArrow } from '../../icons/up-arrow';
import { logOut } from '../../../function-utils/log-out';
import { useAuth } from '../../../context/auth-provider';

interface SidebarProps {
  navigate?: NavigateFunction;
  selectedOption: string;
  // eslint-disable-next-line
  company: Company | any;
}

export const Sidebar: React.FC<SidebarProps> = ({
  navigate,
  company,
  // selectedOption,
}) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false);
  const [lastPartUrl, setLastPartUrl] = useState<string>('');
  const location = useLocation();

  const toggleDropdown = (dropdownName: string) => {
    setIsDropDownOpened(!isDropDownOpened);
    setOpenDropdown(dropdownName);

    if (isDropDownOpened && dropdownName !== openDropdown) {
      setIsDropDownOpened(true);
    }
  };

  const { name } = company;

  const height = useWindowHeight();

  const dashboardClick = () => {
    toggleDropdown('Dashboard');
    navigate && navigate(`/admin`);
  };

  const surveyClick = () => {
    toggleDropdown('Survey');
    navigate && navigate(`/${name}/survey`);
  };

  const settingsClick = () => {
    toggleDropdown('Settings');
    navigate && navigate(`/${name}/settings`);
  };

  const employeeProfileClick = () => {
    toggleDropdown('employee');
    navigate && navigate(`/employee/${company.id}`);
  };

  const getSidebarActiveButtonAccordingUrl = () => {
    const lastPartUrl = location.pathname.split('/').slice(-1).join('/');
    const pathSegments = location.pathname.split('/');
    const secondLastPartUrl = pathSegments[pathSegments.length - 2];
    setLastPartUrl(lastPartUrl);

    if (pathSegments.includes('employees') && pathSegments.length === 4) {
      setIsDropDownOpened(true);

      return setOpenDropdown('Employees');
    }

    if (pathSegments.includes('applicants') && pathSegments.length === 4) {
      setIsDropDownOpened(true);

      return setOpenDropdown('Applicants');
    }

    switch (lastPartUrl) {
      case 'admin':
        return setOpenDropdown('Dashboard');
      case 'job-offers':
        setIsDropDownOpened(true);

        return setOpenDropdown('Jobs');
      case 'create-job-offer':
        setIsDropDownOpened(true);

        return setOpenDropdown('Jobs');
      case 'applicants':
        setIsDropDownOpened(true);

        return setOpenDropdown('Applicants');
      case 'rejected':
        setIsDropDownOpened(true);

        return setOpenDropdown('Applicants');
      case 'hot-prospects':
        setIsDropDownOpened(true);

        return setOpenDropdown('Applicants');
      case 'onboarding':
        setIsDropDownOpened(true);

        return setOpenDropdown('Applicants');
      case 'employees':
        setIsDropDownOpened(true);

        return setOpenDropdown('Employees');
      case 'dayoff':
        setIsDropDownOpened(true);

        return setOpenDropdown('Employees');
      case 'skill-map':
        setIsDropDownOpened(true);

        return setOpenDropdown('Employees');
      case 'admins':
        setIsDropDownOpened(true);

        return setOpenDropdown('Admins');
      case 'add':
        setIsDropDownOpened(true);

        return setOpenDropdown('Admins');
      default:
        // Handle dynamic routes for /employees/:employeeId and /applicant/:applicantsId
        if (secondLastPartUrl === 'employees' && pathSegments.length === 4) {
          setIsDropDownOpened(true);

          return setOpenDropdown('Employees');
        }
        if (secondLastPartUrl === 'applicants' && pathSegments.length === 4) {
          setIsDropDownOpened(true);

          return setOpenDropdown('Applicants');
        }

        return setOpenDropdown('');
    }
  };

  const insideDropdownLinksClick = (
    navigationLink: string,
    buttonName: string
  ) => {
    navigate && navigate(navigationLink);

    setOpenDropdown(buttonName);
    setIsDropDownOpened(true);
  };

  useEffect(() => {
    getSidebarActiveButtonAccordingUrl();
  }, [location.pathname, lastPartUrl]);

  const { setUser } = useAuth();

  const handleNameClick = (entityType: string) => {
    if (entityType === 'company') {
      settingsClick();
    } else {
      employeeProfileClick();
    }
  };

  return (
    <SidebarContainer height={height} fromPage={company.entityType}>
      {company.entityType === 'company' && (
        <ItemsContainer id="items container">
          <Dropdown
            id="admin-dashboard-dropdown"
            isActive={openDropdown === 'Dashboard' || lastPartUrl === 'admin'}
            onClick={() => dashboardClick()}
          >
            <Text pl={8} fontFamily={font.family}>
              <strong>Dashboard</strong>
            </Text>
          </Dropdown>

          <DropDownContainer>
            <Dropdown
              id="jobs-dropdown"
              isActive={
                openDropdown === 'Jobs' ||
                lastPartUrl === 'job-offers' ||
                lastPartUrl === 'create-job-offer'
              }
              onClick={() => toggleDropdown('Jobs')}
            >
              <MenuItem>
                <Text pl={8} fontFamily={font.family}>
                  <strong>Jobs</strong>
                </Text>

                {openDropdown === 'Jobs' && isDropDownOpened ? (
                  <UpArrow />
                ) : openDropdown === 'Jobs' && !isDropDownOpened ? (
                  <DownArrow color={'white'} />
                ) : (
                  <DownArrow color={'black'} />
                )}
              </MenuItem>
            </Dropdown>

            {openDropdown === 'Jobs' && isDropDownOpened && (
              <>
                <DropdownLink
                  onClick={() =>
                    insideDropdownLinksClick(`/${name}/jobs/job-offers`, 'Jobs')
                  }
                  isGradientLink={lastPartUrl === `job-offers`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Job Offers</strong>
                  </Text>
                </DropdownLink>

                <DropdownLink
                  onClick={() =>
                    insideDropdownLinksClick(
                      `/${name}/jobs/create-job-offer`,
                      'Jobs'
                    )
                  }
                  isGradientLink={lastPartUrl === `create-job-offer`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Create Job Offer + </strong>
                  </Text>
                </DropdownLink>
              </>
            )}
          </DropDownContainer>

          <DropDownContainer>
            <Dropdown
              id="applicants-dropdown"
              isActive={
                openDropdown === 'Applicants' ||
                location.pathname === `/${name}/applicants`
              }
              onClick={() => toggleDropdown('Applicants')}
            >
              <MenuItem>
                <Text pl={8} fontFamily={font.family}>
                  <strong>Applicants</strong>
                </Text>

                {openDropdown === 'Applicants' && isDropDownOpened ? (
                  <UpArrow />
                ) : openDropdown === 'Applicants' && !isDropDownOpened ? (
                  <DownArrow color={'white'} />
                ) : (
                  <DownArrow color={'black'} />
                )}
              </MenuItem>
            </Dropdown>

            {openDropdown === 'Applicants' && isDropDownOpened && (
              <>
                <DropdownLink
                  onClick={() => {
                    if (navigate) {
                      navigate(`/${name}/applicants`);
                    }
                  }}
                  isGradientLink={lastPartUrl === `applicants`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Candidates</strong>
                  </Text>
                </DropdownLink>
                <DropdownLink
                  onClick={() => {
                    if (navigate) {
                      navigate(`/${name}/hot-prospects`);
                    }
                  }}
                  isGradientLink={lastPartUrl === `hot-prospects`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Hot Prospect</strong>
                  </Text>
                </DropdownLink>
                <DropdownLink
                  onClick={() => {
                    if (navigate) {
                      navigate(`/${name}/rejected`);
                    }
                  }}
                  isGradientLink={lastPartUrl === `rejected`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Rejected</strong>
                  </Text>
                </DropdownLink>

                {/* <DropdownLink
                onClick={() => {
                  toggleAnalysis && toggleAnalysis();
                  navigate && navigate(`/${name}/applicants/onboarding`);
                  disableAnalysis && disableAnalysis();
                }}
                isGradientLink={lastPartUrl === `onboarding`}
              >
                <Text
                  mt={0}
                  mb={0}
                  pl={8}
                  fontFamily={font.family}
                  fontSize={16}
                >
                  <strong> Onboarding</strong>
                </Text>
              </DropdownLink> */}
              </>
            )}
          </DropDownContainer>

          <DropDownContainer>
            <Dropdown
              id="employees-dropdown"
              isActive={
                openDropdown === 'Employees' ||
                location.pathname.includes(`/${name}/employees`)
              }
              onClick={() => toggleDropdown('Employees')}
            >
              <MenuItem>
                <Text pl={8} fontFamily={font.family}>
                  <strong>Employees</strong>
                </Text>
                {openDropdown === 'Employees' && isDropDownOpened ? (
                  <UpArrow />
                ) : openDropdown === 'Employees' && !isDropDownOpened ? (
                  <DownArrow color={'white'} />
                ) : (
                  <DownArrow color={'black'} />
                )}
              </MenuItem>
            </Dropdown>

            {openDropdown === 'Employees' && isDropDownOpened && (
              <>
                <DropdownLink
                  onClick={() => navigate && navigate(`/${name}/employees`)}
                  isGradientLink={lastPartUrl === `employees`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>All Employees</strong>
                  </Text>
                </DropdownLink>
                <DropdownLink
                  onClick={() =>
                    navigate && navigate(`/${name}/employees/dayoff`)
                  }
                  isGradientLink={lastPartUrl === `dayoff`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Day Off</strong>
                  </Text>
                </DropdownLink>
                <DropdownLink
                  onClick={() =>
                    navigate && navigate(`/${name}/employees/skill-map`)
                  }
                  isGradientLink={lastPartUrl === `skill-map`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong>Skill Map</strong>
                  </Text>
                </DropdownLink>
              </>
            )}
          </DropDownContainer>

          <DropDownContainer>
            <Dropdown
              id="admins-dropdown"
              isActive={
                openDropdown === 'Admins' ||
                location.pathname.includes(`/${name}/admins`)
              }
              onClick={() => toggleDropdown('Admins')}
            >
              <MenuItem>
                <Text pl={8} fontFamily={font.family}>
                  <strong>Admins</strong>
                </Text>
                {openDropdown === 'Admins' && isDropDownOpened ? (
                  <UpArrow />
                ) : openDropdown === 'Admins' && !isDropDownOpened ? (
                  <DownArrow color={'white'} />
                ) : (
                  <DownArrow color={'black'} />
                )}
              </MenuItem>
            </Dropdown>

            {openDropdown === 'Admins' && isDropDownOpened && (
              <>
                <DropdownLink
                  onClick={() => navigate && navigate(`/${name}/admins`)}
                  isGradientLink={lastPartUrl === `admins`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong> All Admins</strong>
                  </Text>
                </DropdownLink>
                <DropdownLink
                  onClick={() => navigate && navigate(`/${name}/admins/add`)}
                  isGradientLink={lastPartUrl === `add`}
                >
                  <Text
                    mt={0}
                    mb={0}
                    pl={8}
                    fontFamily={font.family}
                    fontSize={16}
                  >
                    <strong> Add Admin</strong>
                  </Text>
                </DropdownLink>
              </>
            )}
          </DropDownContainer>
          <Dropdown
            id="survey"
            isActive={openDropdown === 'Survey' || lastPartUrl === 'survey'}
            onClick={() => surveyClick()}
          >
            <Text pl={8} fontFamily={font.family}>
              <strong>Survey</strong>
            </Text>
          </Dropdown>
          <Dropdown
            id="admin-settings"
            isActive={openDropdown === 'Settings' || lastPartUrl === 'settings'}
            onClick={() => settingsClick()}
          >
            <Text pl={8} fontFamily={font.family}>
              <strong>Settings</strong>
            </Text>
          </Dropdown>
        </ItemsContainer>
      )}
      {company.entityType === 'employee' && (
        <ItemsContainer id="items container">
          <Dropdown
            id="employee-profile"
            isActive={
              openDropdown === 'employee' ||
              location.pathname.includes('employee')
            }
            onClick={() => employeeProfileClick()}
          >
            <Text pl={8} fontFamily={font.family}>
              <strong>Profile</strong>
            </Text>
          </Dropdown>
        </ItemsContainer>
      )}
      <LogoutContainer>
        <ButtonContainer onClick={() => logOut(setUser, navigate && navigate)}>
          <ButtonTitle>Logout</ButtonTitle>
        </ButtonContainer>
        <NameContainer onClick={() => handleNameClick(company.entityType)}>
          {company.entityType === 'employee'
            ? company.first_name + ' ' + company.last_name
            : company.name}
        </NameContainer>
      </LogoutContainer>
    </SidebarContainer>
  );
};
