import React from 'react';
import {
  TextContainer,
  CompanyTitle,
  Description,
  CompanySubtitle,
} from './login.styled';
import { Text } from '../../style-utils/text';
import logoTeam from '../../logo_team.png';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Success } from '../icons/success';

const Step2Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
  width: 100%;
`;

export const SignUpStep2 = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Step2Wrapper id="log-in-form">
      <TextContainer>
        <img
          onClick={handleLogoClick}
          style={{
            marginTop: '60px',
            cursor: 'pointer',
          }}
          src={logoTeam}
          width={100}
        />
        <div>
          <CompanyTitle>WorWise Client Board</CompanyTitle>
          <CompanySubtitle>Helping you hire smarter</CompanySubtitle>
          <Description>
            Welcome! Please enter your details to start optimizing your team’s
            hiring process.
          </Description>
        </div>
        <div />
      </TextContainer>
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Success />
        <Text
          fontFamily="Inter, sans-serif"
          fontWeight={700}
          mt={25}
          style={{ alignSelf: 'center' }}
          fontSize={20}
        >
          Check your email for a verification link!
        </Text>
      </div>
    </Step2Wrapper>
  );
};
