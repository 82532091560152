import React, { useEffect, useState } from 'react';
import { SignUpData } from '../hooks/use-auth-forms';
import { Button } from '../layout/design-helpers';
import { InputContainer, Label } from '../login/login.styled';
import { colors } from '../../style-utils/colors';
import './payment-plan.css';
import { SubscriptionPlan } from '../pages/super-admin/subscription-plans/interface';
import { loadSubscriptionPlans } from '../../function-utils/subscription-plan-utils';
import { Spinner } from '../icons/spinner';
import {
  Container,
  PaymentPlansContainer,
  PaymentPlansText,
  PaymentPlansTextContainer,
  TextContainer,
  WarningMessage,
} from './payment-plan.styled';
import { useAuth } from '../../context/auth-provider';

interface PaymentPlansProps {
  setSignUpData: React.Dispatch<React.SetStateAction<SignUpData>>;
  signUpData: SignUpData;
  setSelectedPlan?: (planId: string, amount: number) => void;
  plans?: SubscriptionPlan[];
  isSignUp: boolean;
  defaultSelectedPlanId: string | null;
}

export const PaymentPlans: React.FC<PaymentPlansProps> = ({
  setSignUpData,
  signUpData,
  setSelectedPlan,
  plans: initialPlans,
  isSignUp,
  defaultSelectedPlanId,
}) => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>(initialPlans || []);
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useAuth();

  const localColors = [
    colors.primary_50,
    colors.primary_100,
    colors.primary_300,
    colors.primary_500,
  ];

  useEffect(() => {
    if (!initialPlans) {
      const fetchPlans = async () => {
        setLoading(true);
        try {
          const fetchedPlans = await loadSubscriptionPlans();
          setPlans(fetchedPlans);
        } catch (error) {
          console.error('Error fetching subscription plans:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchPlans();
    }
  }, [initialPlans]);

  useEffect(() => {
    if (defaultSelectedPlanId) {
      setSignUpData({
        ...signUpData,
        paymentPlan: defaultSelectedPlanId,
      });
    }
  }, []);

  const handlePlanSelect = (planId: string, amount: number) => {
    setSignUpData({
      ...signUpData,
      paymentPlan: planId,
    });
    setSelectedPlan && setSelectedPlan(planId, amount);
  };

  const isPlanSelected = (planId: string): boolean => {
    return (
      signUpData.paymentPlan === planId ||
      defaultSelectedPlanId?.toString() === planId
    );
  };

  console.log('Plans:', plans);
  console.log('Sign Up Data Payment Plan:', signUpData.paymentPlan);
  console.log('Default Selected Plan ID:', defaultSelectedPlanId);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <InputContainer>
        <Label>Subscription Plans</Label>
        {!isSignUp && !user?.subscription_plan_id && (
          <WarningMessage>
            This user is not subscribed to any plan.
          </WarningMessage>
        )}
      </InputContainer>
      <Container key={defaultSelectedPlanId}>
        {plans
          ?.filter((plan) => plan.status === 'active')
          .sort(
            (a, b) => parseFloat(a.price.amount) - parseFloat(b.price.amount)
          )
          .map((plan: SubscriptionPlan, i) => {
            const amount = parseFloat(plan.price.amount) * 100;
            console.log(isPlanSelected(plan.id));
            console.log(plan.id);
            const selectedBackground = isPlanSelected(plan.id)
              ? `linear-gradient(to top, ${colors.primary_100}, ${colors.primary_800})`
              : 'white';

            const selectedColor = isPlanSelected(plan.id) ? 'white' : 'black';

            const buttonBackgroundColor = isPlanSelected(plan.id)
              ? `${colors.secondary_700}`
              : 'white';

            const buttonTextColor = isPlanSelected(plan.id)
              ? 'white'
              : `${colors.secondary_700}`;

            return (
              <PaymentPlansContainer
                borderColor={localColors[i % localColors.length]}
                onClick={() => handlePlanSelect(plan.id, amount)}
                key={plan.id}
                bg={selectedBackground}
              >
                <TextContainer color={selectedColor}>
                  <PaymentPlansTextContainer paddingBottom={16}>
                    <PaymentPlansText paddingLeft={16}>
                      <strong>{plan.title}</strong>
                    </PaymentPlansText>
                  </PaymentPlansTextContainer>

                  <PaymentPlansTextContainer>
                    <PaymentPlansText fontSize={18}>
                      {plan.price.amount} {plan.price.currency}
                    </PaymentPlansText>
                  </PaymentPlansTextContainer>
                </TextContainer>

                <Button
                  type="button"
                  backgroundColor={buttonBackgroundColor}
                  textColor={buttonTextColor}
                >
                  Select Plan
                </Button>

                <TextContainer>
                  <PaymentPlansTextContainer>
                    <PaymentPlansText
                      dangerouslySetInnerHTML={{
                        __html: plan.features.join('<br />'),
                      }}
                    />
                  </PaymentPlansTextContainer>
                </TextContainer>
              </PaymentPlansContainer>
            );
          })}
      </Container>
    </>
  );
};
