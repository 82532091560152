import styled from 'styled-components';
import { colors } from '../../../style-utils/colors';
import { font } from '../../../style-utils/text';

export const EmployeeHeading = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: ${colors.neutral_900};
  font-family: ${font.family};
`;

export const BackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  /* width: 200px; */
  cursor: pointer;
  margin-bottom: 15px;
`;

export const EmployeeInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.base_white};
  padding: 25px 20px;
  border-radius: 10px;
  margin-bottom: 25px;
  position: relative;
`;

export const EditAndChangeEmployeeStatusWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
`;

export const EditWrapper = styled.button<{ opacity?: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  /* flex: 0 0 auto; */
  align-self: end;
  opacity: ${({ opacity }) => (opacity ? '0.2' : '1')};
`;

export const ChangeEmployeeStatusBtn = styled.button`
  box-sizing: border-box;
  padding: 8px 16px;
  background-color: ${colors.primary_500};
  border-radius: 4px;
  border-style: none;
  cursor: pointer;
`;

export const EmployeePersonalInfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
  padding: 0 50px;
`;

export const ProfileImageContainer = styled.div`
  box-sizing: border-box;
  & > img {
    /* display: block; */
    width: 162px;
    height: 162px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const PersonalInfoContainer = styled.div`
  width: 100%;
  /* background-color: rebeccapurple; */
`;

export const PersonalInfoWrapper = styled.div<{ gap?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // justify-content: space-between;
  /* gap: ${({ gap }) => gap && `${gap};`}; */
  gap: 8px 16px;
  // flex-wrap: wrap;
  // width: 100%;
  margin-bottom: 8px;
`;

export const ListContainer = styled.div<{ width?: string }>`
  display: flex;
  gap: 10px;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin: 0;
  /* width: ${({ width }) => width && `${width};`}; */
`;

export const EmployeeDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const EmployeeDetailsContainer = styled.div<{
  justify?: string;
  alignItems?: string;
}>`
  display: flex;
  justify-content: ${({ justify }) => justify && `${justify};`};
  align-items: ${({ alignItems }) => alignItems && `${alignItems};`};
  width: 100%;
  margin-bottom: 10px;
`;

export const TabButtons = styled.div`
  display: flex;
  gap: 25px;
`;

export const TabChangeButton = styled.button<{ active: boolean }>`
  all: unset;
  cursor: pointer;
  font-weight: 700;
  padding: 8px 12px;
  border-radius: 5px;
  color: ${(props) =>
    props.active ? `${colors.base_white}` : `${colors.base_black}`};
  border: ${(props) =>
    props.active ? `1px solid ${colors.primary_400}` : 'none'};
  background-color: ${(props) =>
    props.active ? `${colors.primary_400}` : 'none'};
  font-family: ${font.family};
`;

export const EmployeeDetailHeader = styled.div<{ mb?: string }>`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ mb }) => mb && `${mb};`};
`;
export const EmployeeAttachButton = styled.button`
  box-sizing: border-box;
  border: 0;
  border-style: none;
  background-color: ${colors.secondary_400};
  color: ${colors.base_white};
  font-family: ${font.family};
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
  padding: 8px 12px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
`;
export const EmployeeTileOffApplicationButton = styled(EmployeeAttachButton)`
  background-color: ${colors.primary_500};
`;
export const EmployeeDetailDocumentTable = styled.div<{
  mt?: string;
  mb?: string;
}>`
  margin-top: ${({ mt }) => (mt ? mt : '0px')};
  margin-bottom: ${({ mb }) => (mb ? mb : '0px')};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const EmployeeDetailDocumentTableHeaders = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 12px;
  flex-wrap: no-wrap;
`;
export const EmployeeDetailDocumentTableHeader = styled.div<{
  bgColor?: string;
  padding?: string;
  width?: string;
  fillWidth?: boolean;
  gap?: string;
  cursor?: string;
}>`
  box-sizing: border-box;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ width }) => width && `width: ${width};`};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ gap }) => gap && `gap: ${gap};`};
  height: 54px;
  ${({ fillWidth }) => fillWidth && `flex: 1;`};
  ${({ cursor }) => cursor && `cursor: pointer;`};
`;

export const EmployeeDetailStyledLabelAndInputWrapper = styled.div<{
  position?: string;
  width?: string;
  height?: string;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* position: ${({ position }) => position && `${position};`};
  width: ${({ width }) => width && `${width};`};
  height: ${({ height }) => height && `${height};`}; */
`;

export const EmployeeDetailStyledLabel = styled.label`
  box-sizing: border-box;
  color: ${colors.neutral_700};
  font-family: ${font.family};
`;

export const EmployeeDetailStyledInput = styled.input<{
  width?: string;
  padding?: boolean;
}>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: none;
  padding: ${({ padding }) => (padding ? `8px 16px;` : `14px 16px;`)};
  background: ${colors.neutral_50};
  font-family: ${font.family};
  width: ${({ width }) => width && `${width};`};

  ::placeholder {
    color: ${colors.neutral_700};
  }

  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
    background: ${colors.dashboard_light_grey};
  }
`;

export const WrapperColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const StyledEmployeeImage = styled.img`
  box-sizing: border-box;
  height: 162px;
  width: 162px;
  border-radius: 50%;
  object-fit: cover;
`;

export const EmployeeDocumentsNewDocumentsMain = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

export const EmployeeDocumentsNewDocuments = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.white};
  padding: 32px;
  border-radius: 8px;
  width: 400px;
  height: 405px;
  overflow-y: auto;
  z-index: 10;
`;

export const EmployeeDocumentsCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmployeeDocumentsAttachFileContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const EmployeeDocumentsTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  resize: none;
  background-color: ${colors.neutral_50};
  border-radius: 8px;
  padding: 12px 16px;
  border-style: none;
  &:focus {
    outline: 2px solid ${colors.primary_100};
  }
`;

export const EmployeeDocumentsActionIconBtn = styled.button`
  box-sizing: border-box;
  border-style: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const StyledEmployeeTimeOffRemainingDaysAndCreateNewRequestWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const StyledEmployeeTimeOffRemainingDaysWrapper = styled.div`
  box-sizing: border-box;
  border: 0;
  border-style: none;
  background-color: ${colors.secondary_500};
  color: ${colors.base_white};
  font-family: ${font.family};
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  padding: 8px 16px;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
`;

export const StyledDocumentsDeleteDocumentModalMainWrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export const StyledDocumentsDeleteDocumentModalWrapper = styled.div`
  box-sizing: border-box;
  width: 670px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background-color: ${colors.creme_100};
  border-radius: 16px;
`;

export const StyledDocumentsDeleteDocumentModalBtnsWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 16px;
  margin-left: auto;
`;

export const StyledDocumentsDeleteDocumentModalSubmitBtn = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-left: auto;
  padding: 16px 24px;
  background-color: ${colors.primary_500};
  font-family: ${font.family};
  color: ${colors.base_white};
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  border-style: none;
  cursor: pointer;
`;

export const StyledDocumentsDeleteDocumentModalBackBtn = styled(
  StyledDocumentsDeleteDocumentModalSubmitBtn
)`
  background-color: ${colors.primary_100};
  color: ${colors.base_black};
`;
