import React, { useEffect, useState } from 'react';
import { Sidebar } from '../../../layout/sidebar/sidebar';
import {
  ContentWrapper,
  LayoutContainer,
  NavbarAndContentContainer,
  MainContentWrapper,
} from '../../../layout/layout-container.styled';
import { DefaultComponentProps } from '../../../app-router/component-props';
import { useWindowHeight } from '../../../../style-utils/use-window-height';
import { TopMenu } from '../../../layout/top-menu/top-menu';
import { fetchAllItems, fetchItemById } from '../../../requests/requests';
import { Applicant } from '../onboarding/onboarding-table';
import { useLocation, useParams } from 'react-router-dom';
import { JobOfferData } from '../../../../interfaces/job-interfaces';
import {
  AIContainer,
  AIInfo,
  Flex,
  ParsedAiAnalysisData,
  delimiterFix,
  extractPercentage,
  getAiData,
  isValidJson,
} from '../ai-analysis/ai-analysis';
import { ApplicantsContentWrapper } from '../candidates/candidates.styled';
// import { BackContainer } from '../../employees/employee.styled';
// import { LeftArrow } from '../../../icons/left-arrow';
import { Text, font } from '../../../../style-utils/text';
import { colors } from '../../../../style-utils/colors';
import { EnvelopeIcon } from '../../../icons/envelope';
import { PhoneIcon } from '../../../icons/phone';
import { ButtonContainer } from '../../../login/login.styled';
import {
  AIInfoDocumentAttachmentContainer,
  AIInfoHeader,
  AIInfoInterviewQuestionsGrid,
  AIInfoInterviewQuestionsGridItem,
  AIInfoInterviewQuestionsNumber,
  AIInfoKeySkillsContainer,
  AIInfoKeySkillsTab,
  AIInfoPercentageContainer,
  AIInfoPercentageNameAndPercentageContainer,
  AIInfoPercentageSpan,
  AIInfoProfilePictureContainer,
  AIInfoStyledListItem,
  AIInfoStyledUnnorderedList,
  AIInfoTextContainer,
  ContainerStrengthsWeaknesses,
  StrengthsContainer,
  WeaknessesContainer,
  Headings,
  AIInfoKeySkillAndLevelTab,
  AIInfoKeyProjectsProjectsWrapper,
  AIInfoKeyProjectsProjectWrapper,
  AIInfoKeyProjectsProjectAndSkillsWrapper,
  AIInfoKeyProjectsProjectSkillsWrapper,
  AIInfoSoftSkillWrapper,
  AIInfoSoftSkillsItemsWrapperWrapper,
} from './candidate-profile-styled';
import UserDemoImage from '../../../../assets/images/img_avatar.png';
import { RedCircleIcon } from '../../../icons/red-circle-icon';
import { DownloadIcon } from '../../../icons/download-icon';
import { EnvelopeIconCyan } from '../../../icons/envelope-icon-cyan';
import { PhoneIconCyan } from '../../../icons/phone-icon-cyan';
// import { BlueCircleIcon } from '../../../icons/blue-circle-icon';

export const CandidateProfile: React.FC<DefaultComponentProps> = ({
  navigate,
  user,
}) => {
  const height = useWindowHeight();
  const [candidate, setCandidate] = useState<Applicant>();
  const [jobApplied, setJobApplied] = useState<JobOfferData>();
  const [parsedData, setParsedData] = useState<
    ParsedAiAnalysisData | string[] | null
  >(null);
  const { applicantId } = useParams();
  const location = useLocation();
  const [selectedJobOfferFromFilter] = useState<JobOfferData | null>(
    location.state?.selectedJobOfferFromFilter || null
  );
  const fromPage = location.state?.fromPage || 'defaultPage';

  const applicantFromDb = async () => {
    const response = await fetchAllItems(`applicant/${applicantId}`);
    console.log('response = ', response);
    setCandidate(response as unknown as Applicant);
  };

  useEffect(() => {
    console.log('Getted state:', selectedJobOfferFromFilter?.job_position);
    applicantFromDb();
  }, [applicantId]);

  useEffect(() => {
    const getJob = async () => {
      if (candidate) {
        const job = await fetchItemById('jobs', candidate.job_id);
        setJobApplied(job as JobOfferData);
      }
    };
    getJob();
  }, [candidate]);

  useEffect(() => {
    if (candidate?.ai_info) {
      const inputString = candidate.ai_info;

      // Check if the input string is valid JSON
      if (isValidJson(inputString)) {
        try {
          const jsonObject = JSON.parse(inputString);
          setParsedData(jsonObject);
          // setError(null);
        } catch (e) {
          // setError('Error parsing JSON data');
          setParsedData(null);
        }
      } else {
        // Handle as the old data format
        const fixedArray = delimiterFix(inputString);
        if (fixedArray) {
          const textArray = getAiData(fixedArray);
          setParsedData(textArray);
        }
        // setError(null);
      }
    } else {
      setParsedData(null);
      // setError(null);
    }
  }, [candidate?.ai_info]);

  return (
    <LayoutContainer height={height} key={candidate?.id}>
      <TopMenu />
      <MainContentWrapper>
        <Sidebar company={user} selectedOption={''} navigate={navigate} />
        <ContentWrapper>
          <NavbarAndContentContainer>
            <ApplicantsContentWrapper>
              <AIContainer>
                <ButtonContainer
                  mt={1}
                  backgroundColor={colors.primary_500}
                  onClick={() => {
                    if (navigate) {
                      switch (fromPage) {
                        case 'hotCandidates':
                          navigate(`/${user.name}/hot-prospects`, {
                            state: { selectedJobOfferFromFilter },
                          });
                          break;
                        case 'todayInterviews':
                          navigate(`/${user.name}/interviews-today`, {
                            state: { selectedJobOfferFromFilter },
                          });
                          break;
                        case 'rejectedCandidates':
                          navigate(`/${user.name}/hot-prospect/rejected`, {
                            state: { selectedJobOfferFromFilter },
                          });
                          break;
                        default:
                          navigate(`/${user.name}/applicants`, {
                            state: { selectedJobOfferFromFilter },
                          });
                          break;
                      }
                    }
                  }}
                >
                  <Text mt={0} mb={0} fontWeight={700} fontFamily={font.family}>
                    Back to Candidates
                  </Text>
                </ButtonContainer>
                {Array.isArray(parsedData) ? (
                  // For array parsed data
                  <AIInfo>
                    <Text mt={0} mb={10} fontSize={36} fontFamily={font.family}>
                      {candidate?.first_name}&nbsp;{candidate?.last_name}&nbsp;
                      {parsedData && extractPercentage(parsedData[0])}
                    </Text>
                    <Flex>
                      <Text
                        mt={0}
                        mb={8}
                        fontSize={16}
                        fontFamily={font.family}
                        fontWeight={700}
                      >
                        Job Applied For:&nbsp;
                      </Text>
                      <Text
                        display="block"
                        mt={0}
                        mb={0}
                        fontSize={16}
                        fontFamily={font.family}
                      >
                        {jobApplied?.job_position}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text
                        mt={0}
                        mb={8}
                        fontSize={16}
                        fontFamily={font.family}
                        fontWeight={700}
                      >
                        Description:&nbsp;
                      </Text>
                      <Text
                        display="block"
                        mt={0}
                        mb={0}
                        fontSize={16}
                        fontFamily={font.family}
                        dangerouslySetInnerHTML={{
                          __html: jobApplied ? jobApplied?.job_description : '',
                        }}
                      />
                    </Flex>
                    <Flex>
                      <EnvelopeIcon />
                      <Text
                        mt={0}
                        mb={0}
                        ml={10}
                        fontSize={16}
                        fontFamily={font.family}
                      >
                        {candidate?.email}
                      </Text>
                    </Flex>
                    <Flex>
                      <PhoneIcon />
                      <Text
                        mt={0}
                        mb={0}
                        ml={10}
                        fontSize={16}
                        fontFamily={font.family}
                      >
                        {candidate?.phone_number}
                      </Text>
                    </Flex>
                  </AIInfo>
                ) : (
                  // For JSON parsed Data
                  <AIInfoHeader>
                    <AIInfoProfilePictureContainer>
                      <img
                        src={UserDemoImage}
                        alt="profile picture placeholder"
                      />
                    </AIInfoProfilePictureContainer>
                    <div style={{ width: '100%' }}>
                      <AIInfoPercentageNameAndPercentageContainer>
                        <Text
                          margin="0"
                          fontSize={36}
                          fontFamily={font.family}
                          lineHeight="43.2"
                          fontWeight={700}
                        >
                          {candidate?.first_name}&nbsp;{candidate?.last_name}
                        </Text>
                        <AIInfoPercentageContainer>
                          <RedCircleIcon />
                          <Text
                            display="block"
                            fontFamily={font.family}
                            margin="0"
                            color={colors.base_black}
                            fontSize={18}
                            lineHeight="27"
                          >
                            <AIInfoPercentageSpan>
                              {parsedData?.percentage_match + '%'}
                            </AIInfoPercentageSpan>
                            &nbsp;Match
                          </Text>
                        </AIInfoPercentageContainer>
                      </AIInfoPercentageNameAndPercentageContainer>
                      <AIInfoTextContainer>
                        <Text
                          margin="0"
                          fontSize={16}
                          fontFamily={font.family}
                          fontWeight={700}
                          lineHeight="19.2"
                        >
                          Job Applied For:&nbsp;
                        </Text>
                        <Text
                          margin="0"
                          fontSize={16}
                          fontFamily={font.family}
                          lineHeight="19.2"
                        >
                          {jobApplied?.job_position}
                        </Text>
                      </AIInfoTextContainer>
                      <AIInfoTextContainer>
                        <Text
                          margin="12"
                          fontSize={16}
                          fontFamily={font.family}
                          fontWeight={700}
                          lineHeight="19.2"
                        >
                          Description:&nbsp;
                        </Text>
                        <Text
                          display="block"
                          mt={0}
                          mb={0}
                          fontSize={16}
                          fontFamily={font.family}
                          dangerouslySetInnerHTML={{
                            __html: jobApplied
                              ? jobApplied?.job_description
                              : '',
                          }}
                        />
                      </AIInfoTextContainer>
                      <AIInfoTextContainer gap="12px" alignItems="center">
                        <Text
                          margin="0"
                          fontSize={16}
                          fontFamily={font.family}
                          fontWeight={700}
                          lineHeight="19.2"
                        >
                          Document Attachment:&nbsp;
                        </Text>
                        <AIInfoDocumentAttachmentContainer>
                          <Text
                            margin="0"
                            fontSize={16}
                            fontFamily={font.family}
                            lineHeight="19.2"
                          >
                            CV
                          </Text>
                          {candidate && candidate.cv ? (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                window.open(candidate.cv, '_blank')
                              }
                            >
                              <DownloadIcon />
                            </div>
                          ) : (
                            <Text
                              margin="0"
                              fontSize={14}
                              fontFamily={font.family}
                            >
                              No CV attached
                            </Text>
                          )}
                        </AIInfoDocumentAttachmentContainer>
                        <AIInfoDocumentAttachmentContainer>
                          <Text
                            margin="0"
                            fontSize={16}
                            fontFamily={font.family}
                            lineHeight="19.2"
                          >
                            Portfolio
                          </Text>
                          {candidate && candidate.portfolio ? (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                window.open(candidate.portfolio, '_blank')
                              }
                            >
                              <DownloadIcon />
                            </div>
                          ) : (
                            <Text
                              margin="0"
                              fontSize={14}
                              fontFamily={font.family}
                              lineHeight="16.8"
                            >
                              No portoflio attached
                            </Text>
                          )}
                        </AIInfoDocumentAttachmentContainer>
                      </AIInfoTextContainer>
                      <AIInfoTextContainer gap="24px">
                        <AIInfoDocumentAttachmentContainer>
                          <EnvelopeIconCyan />
                          <Text
                            margin="0 0 1px 0"
                            fontSize={14}
                            fontFamily={font.family}
                            lineHeight="16.8"
                            color={colors.neutral_700}
                          >
                            {candidate?.email}
                          </Text>
                        </AIInfoDocumentAttachmentContainer>
                        <AIInfoDocumentAttachmentContainer>
                          <PhoneIconCyan />
                          <Text
                            margin="0 0 1px 0"
                            fontSize={14}
                            fontFamily={font.family}
                            lineHeight="16.8"
                            color={colors.neutral_700}
                          >
                            {candidate?.phone_number}
                          </Text>
                        </AIInfoDocumentAttachmentContainer>
                      </AIInfoTextContainer>
                    </div>
                  </AIInfoHeader>
                )}
                {/* Mapping array from parsed data */}
                {Array.isArray(parsedData) ? (
                  parsedData?.map((text, i) => {
                    return (
                      <AIInfo key={i} isPreline>
                        <Text
                          fontWeight={300}
                          mt={0}
                          mb={0}
                          fontFamily={font.family}
                        >
                          {text}
                        </Text>
                      </AIInfo>
                    );
                  })
                ) : (
                  // Displaying the JSON object from parsed data
                  <>
                    <AIInfo isPreline>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Profile Match
                      </Text>
                      {parsedData?.profile_match &&
                      parsedData.profile_match.trim().length > 0 ? (
                        <Text
                          fontFamily={font.family}
                          fontSize={14}
                          lineHeight="16.8"
                          color={colors.base_black}
                          margin="0"
                        >
                          {parsedData.profile_match}
                        </Text>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No profile match available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        IT Experience
                      </Text>
                      {parsedData?.it_experience &&
                      parsedData.it_experience.trim().length > 0 ? (
                        <Text
                          fontFamily={font.family}
                          fontSize={14}
                          lineHeight="16.8"
                          color={colors.base_black}
                          margin="0"
                        >
                          {parsedData.it_experience}
                        </Text>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No it experience available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 24px 0"
                      >
                        Key skills
                      </Text>
                      <AIInfoKeySkillsContainer>
                        {parsedData?.key_skills &&
                        parsedData.key_skills.length > 0 ? (
                          parsedData?.key_skills.map((skill, index) => {
                            return (
                              <AIInfoKeySkillAndLevelTab
                                borderColor={colors.grey_light}
                                key={index}
                              >
                                <Text
                                  fontFamily={font.family}
                                  fontWeight={700}
                                  fontSize={14}
                                  lineHeight="16.8"
                                  color={colors.base_black}
                                  margin="0"
                                >
                                  {skill.skill}
                                </Text>
                                <Text
                                  fontFamily={font.family}
                                  fontWeight={700}
                                  fontSize={10}
                                  lineHeight="16.8"
                                  color={colors.primary_300}
                                  margin="0"
                                >
                                  {skill.proficiency}
                                </Text>
                              </AIInfoKeySkillAndLevelTab>
                            );
                          })
                        ) : (
                          <Text
                            fontFamily={font.family}
                            fontWeight={700}
                            fontSize={18}
                            lineHeight="16.8"
                            color={colors.grey_light}
                            margin="0"
                          >
                            No skills available
                          </Text>
                        )}
                      </AIInfoKeySkillsContainer>
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 16px 0"
                      >
                        Past experience
                      </Text>
                      {parsedData?.past_experience &&
                      parsedData.past_experience.length > 0 ? (
                        parsedData?.past_experience.map((exp, index) => {
                          return (
                            <div key={index}>
                              <Text
                                fontFamily={font.family}
                                fontWeight={500}
                                fontSize={16}
                                lineHeight="25"
                                color={colors.base_black}
                                margin="0 0 8px 0"
                              >
                                {exp}
                              </Text>
                            </div>
                          );
                        })
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No past experience available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 24px 0"
                      >
                        Key Projects
                      </Text>
                      {parsedData?.key_projects &&
                      parsedData.key_projects.length > 0 ? (
                        <AIInfoKeyProjectsProjectsWrapper>
                          {parsedData?.key_projects.map((project, index) => {
                            return (
                              <AIInfoKeyProjectsProjectAndSkillsWrapper
                                key={index}
                              >
                                <AIInfoKeyProjectsProjectWrapper>
                                  <Text
                                    fontFamily={font.family}
                                    fontSize={14}
                                    lineHeight="16.8"
                                    color={colors.primary_500}
                                    fontWeight={700}
                                    margin="0"
                                  >
                                    {project.project_name}
                                  </Text>
                                  <Text
                                    fontFamily={font.family}
                                    fontSize={14}
                                    lineHeight="16.8"
                                    color={colors.base_black}
                                    margin="0"
                                  >
                                    {project.outcomes}
                                  </Text>
                                </AIInfoKeyProjectsProjectWrapper>
                                <AIInfoKeyProjectsProjectSkillsWrapper>
                                  {project.technologies.map((skill, index) => (
                                    <Text
                                      key={index}
                                      fontFamily={font.family}
                                      fontSize={12}
                                      lineHeight="16.8"
                                      fontWeight={500}
                                      color={colors.grey}
                                      margin="0"
                                    >
                                      {skill}
                                    </Text>
                                  ))}
                                </AIInfoKeyProjectsProjectSkillsWrapper>
                              </AIInfoKeyProjectsProjectAndSkillsWrapper>
                            );
                          })}
                        </AIInfoKeyProjectsProjectsWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No key projects available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <ContainerStrengthsWeaknesses>
                        <StrengthsContainer>
                          <Headings>
                            <Text
                              fontFamily={font.family}
                              fontWeight={700}
                              fontSize={20}
                              lineHeight="24"
                              color={colors.base_black}
                              margin="0"
                            >
                              Key Strengths
                            </Text>
                          </Headings>
                          <AIInfoKeySkillsContainer>
                            {parsedData?.key_strengths &&
                            parsedData.key_strengths.length > 0 ? (
                              parsedData?.key_strengths.map(
                                (strength, index) => {
                                  return (
                                    <AIInfoKeySkillsTab
                                      bgColor={colors.secondary_500}
                                      key={index}
                                    >
                                      <Text
                                        fontFamily={font.family}
                                        fontWeight={700}
                                        fontSize={14}
                                        lineHeight="16.8"
                                        color={colors.base_white}
                                        margin="0"
                                      >
                                        {strength}
                                      </Text>
                                    </AIInfoKeySkillsTab>
                                  );
                                }
                              )
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontWeight={700}
                                fontSize={18}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No key strenghts available
                              </Text>
                            )}
                          </AIInfoKeySkillsContainer>
                        </StrengthsContainer>

                        <WeaknessesContainer>
                          <Headings>
                            <Text
                              fontFamily={font.family}
                              fontWeight={700}
                              fontSize={20}
                              lineHeight="24"
                              color={colors.base_black}
                              margin="0"
                            >
                              Weaknesses
                            </Text>
                          </Headings>
                          {parsedData?.weaknesses &&
                          parsedData.weaknesses.length > 0 ? (
                            <AIInfoKeySkillsContainer>
                              {parsedData?.weaknesses.map((weakness, index) => {
                                return (
                                  <AIInfoKeySkillsTab
                                    bgColor={colors.primary_500}
                                    key={index}
                                  >
                                    <Text
                                      fontFamily={font.family}
                                      fontWeight={700}
                                      fontSize={14}
                                      lineHeight="16.8"
                                      color={colors.base_white}
                                      margin="0"
                                    >
                                      {weakness}
                                    </Text>
                                  </AIInfoKeySkillsTab>
                                );
                              })}
                            </AIInfoKeySkillsContainer>
                          ) : (
                            <Text
                              fontFamily={font.family}
                              fontWeight={700}
                              fontSize={18}
                              lineHeight="16.8"
                              color={colors.grey_light}
                              margin="0"
                            >
                              No weaknesses available
                            </Text>
                          )}
                        </WeaknessesContainer>
                      </ContainerStrengthsWeaknesses>
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Career Progression
                      </Text>
                      {parsedData?.career_progression &&
                      parsedData.career_progression.trim().length > 0 ? (
                        <Text
                          fontFamily={font.family}
                          fontSize={14}
                          lineHeight="16.8"
                          color={colors.base_black}
                          margin="0"
                        >
                          {parsedData?.career_progression}
                        </Text>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No career progression available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Educational Background
                      </Text>
                      {parsedData?.educational_background &&
                      parsedData.educational_background.length > 0 ? (
                        <AIInfoStyledUnnorderedList>
                          {parsedData?.educational_background.map(
                            (education, index) => {
                              return (
                                <AIInfoStyledListItem key={index}>
                                  {education}
                                </AIInfoStyledListItem>
                              );
                            }
                          )}
                        </AIInfoStyledUnnorderedList>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No educational background available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Certifications
                      </Text>
                      {parsedData?.recommendations &&
                      parsedData.recommendations.length > 0 ? (
                        <AIInfoStyledUnnorderedList>
                          {parsedData?.certifications.map(
                            (certification, index) => {
                              return (
                                <AIInfoStyledListItem key={index}>
                                  {certification}
                                </AIInfoStyledListItem>
                              );
                            }
                          )}
                        </AIInfoStyledUnnorderedList>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No certifications info available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Achievements
                      </Text>
                      {parsedData?.achievements &&
                      parsedData.achievements.trim().length > 0 ? (
                        <Text
                          fontFamily={font.family}
                          fontSize={14}
                          lineHeight="16.8"
                          color={colors.base_black}
                          margin="0"
                        >
                          {parsedData?.achievements}
                        </Text>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No achievments available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Team collaboration
                      </Text>
                      {parsedData?.team_collaboration &&
                      parsedData.team_collaboration.trim().length > 0 ? (
                        <Text
                          fontFamily={font.family}
                          fontSize={14}
                          lineHeight="16.8"
                          color={colors.base_black}
                          margin="0"
                        >
                          {parsedData?.team_collaboration}
                        </Text>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No team collaborations available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Career aspirations
                      </Text>
                      {parsedData?.career_aspirations &&
                      parsedData.career_aspirations.trim().length > 0 ? (
                        <Text
                          fontFamily={font.family}
                          fontSize={14}
                          lineHeight="16.8"
                          color={colors.base_black}
                          margin="0"
                        >
                          {parsedData?.career_aspirations}
                        </Text>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No career aspirations available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Soft skills
                      </Text>
                      {parsedData?.soft_skills &&
                      parsedData.soft_skills.conflict_resolution.trim().length >
                        0 &&
                      parsedData.soft_skills.emotional_intelligence.trim()
                        .length > 0 ? (
                        <AIInfoSoftSkillsItemsWrapperWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Emotional intelligence -
                            </Text>
                            {parsedData?.soft_skills &&
                            parsedData.soft_skills.emotional_intelligence.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.soft_skills.emotional_intelligence}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No emotional intelligence info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Conflict resolution -
                            </Text>
                            {parsedData?.soft_skills &&
                            parsedData.soft_skills.conflict_resolution.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.soft_skills.conflict_resolution}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No conflict resolution info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                        </AIInfoSoftSkillsItemsWrapperWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No soft skills available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Learning agility
                      </Text>
                      {parsedData?.learning_agility &&
                      parsedData.learning_agility.continuous_learning.trim()
                        .length > 0 &&
                      parsedData.learning_agility.adaptation.trim().length >
                        0 ? (
                        <AIInfoSoftSkillsItemsWrapperWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Continuous learning -
                            </Text>
                            {parsedData?.learning_agility &&
                            parsedData.learning_agility.continuous_learning.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {
                                  parsedData?.learning_agility
                                    .continuous_learning
                                }
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No Continuous learning info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Adaptation -
                            </Text>
                            {parsedData?.learning_agility &&
                            parsedData.learning_agility.adaptation.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.learning_agility.adaptation}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No adaptation info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                        </AIInfoSoftSkillsItemsWrapperWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No learning agility info available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Cultural fit
                      </Text>
                      {parsedData?.cultural_fit &&
                      parsedData.cultural_fit.global_experience.trim().length >
                        0 &&
                      parsedData.cultural_fit.cultural_competency.trim()
                        .length > 0 ? (
                        <AIInfoSoftSkillsItemsWrapperWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Global experience -
                            </Text>
                            {parsedData?.cultural_fit &&
                            parsedData.cultural_fit.global_experience.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.cultural_fit.global_experience}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No global experience info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Cultural competency -
                            </Text>
                            {parsedData?.cultural_fit &&
                            parsedData.cultural_fit.cultural_competency.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.cultural_fit.cultural_competency}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No cultural competency info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                        </AIInfoSoftSkillsItemsWrapperWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No cultural fit info available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Diversity and inclusion
                      </Text>
                      {parsedData?.diversity_and_inclusion &&
                      parsedData.diversity_and_inclusion.DEI_initiatives.trim()
                        .length > 0 &&
                      parsedData.diversity_and_inclusion.inclusive_leadership.trim()
                        .length > 0 ? (
                        <AIInfoSoftSkillsItemsWrapperWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              DEI initiatives -
                            </Text>
                            {parsedData?.diversity_and_inclusion &&
                            parsedData.diversity_and_inclusion.DEI_initiatives.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {
                                  parsedData?.diversity_and_inclusion
                                    .DEI_initiatives
                                }
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No DEI initiatives info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Inclusive leadership -
                            </Text>
                            {parsedData?.diversity_and_inclusion &&
                            parsedData.diversity_and_inclusion.inclusive_leadership.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {
                                  parsedData?.diversity_and_inclusion
                                    .inclusive_leadership
                                }
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No inclusive leadership info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                        </AIInfoSoftSkillsItemsWrapperWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No diversity and inclusion info
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Resilience
                      </Text>
                      {parsedData?.resilience &&
                      parsedData.resilience.challenges_overcome.trim().length >
                        0 &&
                      parsedData.resilience.adaptability.trim().length > 0 ? (
                        <AIInfoSoftSkillsItemsWrapperWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Challenges overcome -
                            </Text>
                            {parsedData?.resilience &&
                            parsedData.resilience.challenges_overcome.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.resilience.challenges_overcome}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No challenges overcome info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Adaptability -
                            </Text>
                            {parsedData?.resilience &&
                            parsedData.resilience.adaptability.trim().length >
                              0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.resilience.adaptability}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No adaptability info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                        </AIInfoSoftSkillsItemsWrapperWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No resilience info
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Long term impact
                      </Text>
                      {parsedData?.long_term_impact &&
                      parsedData.long_term_impact.legacy_projects.trim()
                        .length > 0 &&
                      parsedData.long_term_impact.sustained_contributions.trim()
                        .length > 0 ? (
                        <AIInfoSoftSkillsItemsWrapperWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Legacy projects -
                            </Text>
                            {parsedData?.long_term_impact &&
                            parsedData.long_term_impact.legacy_projects.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {parsedData?.long_term_impact.legacy_projects}
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No legacy projects info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                          <AIInfoSoftSkillWrapper>
                            <Text
                              fontFamily={font.family}
                              fontSize={14}
                              lineHeight="16.8"
                              color={colors.secondary_400}
                              fontWeight={700}
                              margin="0"
                            >
                              Sustained contributions -
                            </Text>
                            {parsedData?.long_term_impact &&
                            parsedData.long_term_impact.sustained_contributions.trim()
                              .length > 0 ? (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.base_black}
                                margin="0"
                              >
                                {
                                  parsedData?.long_term_impact
                                    .sustained_contributions
                                }
                              </Text>
                            ) : (
                              <Text
                                fontFamily={font.family}
                                fontSize={14}
                                lineHeight="16.8"
                                color={colors.grey_light}
                                margin="0"
                              >
                                No sustained contributions info
                              </Text>
                            )}
                          </AIInfoSoftSkillWrapper>
                        </AIInfoSoftSkillsItemsWrapperWrapper>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No long term impact info
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Digital presence
                      </Text>
                      {parsedData?.digital_presence &&
                      parsedData.digital_presence.length > 0 ? (
                        <AIInfoStyledUnnorderedList>
                          {parsedData?.digital_presence.map((link, index) => {
                            return (
                              <AIInfoStyledListItem key={index}>
                                <a href={link}>{link}</a>
                              </AIInfoStyledListItem>
                            );
                          })}
                        </AIInfoStyledUnnorderedList>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No digital presence info available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 8px 0"
                      >
                        Recommendations
                      </Text>
                      {parsedData?.recommendations &&
                      parsedData.recommendations.length > 0 ? (
                        <AIInfoStyledUnnorderedList>
                          {parsedData?.recommendations.map(
                            (reccomendation, index) => {
                              return (
                                <AIInfoStyledListItem key={index}>
                                  {reccomendation}
                                </AIInfoStyledListItem>
                              );
                            }
                          )}
                        </AIInfoStyledUnnorderedList>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No recommendations info available
                        </Text>
                      )}
                    </AIInfo>
                    <AIInfo>
                      <Text
                        fontFamily={font.family}
                        fontWeight={700}
                        fontSize={20}
                        lineHeight="24"
                        color={colors.base_black}
                        margin="0 0 24px 0"
                      >
                        Interview Questions
                      </Text>
                      {parsedData?.interview_questions &&
                      parsedData.interview_questions.length > 0 ? (
                        <AIInfoInterviewQuestionsGrid>
                          {parsedData?.interview_questions.map(
                            (question, index) => {
                              return (
                                <AIInfoInterviewQuestionsGridItem key={index}>
                                  <AIInfoInterviewQuestionsNumber>
                                    <Text
                                      fontFamily={font.family}
                                      fontSize={14}
                                      lineHeight="16.8"
                                      color={colors.base_white}
                                      margin="0"
                                    >
                                      {index + 1}
                                    </Text>
                                  </AIInfoInterviewQuestionsNumber>
                                  <Text
                                    fontFamily={font.family}
                                    fontSize={14}
                                    lineHeight="16.8"
                                    color={colors.base_black}
                                    margin="0"
                                  >
                                    {question}
                                  </Text>
                                </AIInfoInterviewQuestionsGridItem>
                              );
                            }
                          )}
                        </AIInfoInterviewQuestionsGrid>
                      ) : (
                        <Text
                          fontFamily={font.family}
                          fontWeight={700}
                          fontSize={18}
                          lineHeight="16.8"
                          color={colors.grey_light}
                          margin="0"
                        >
                          No interview questions available
                        </Text>
                      )}
                    </AIInfo>
                  </>
                )}
                <Flex>
                  <ButtonContainer
                    style={{ marginLeft: 'auto' }}
                    mt={1}
                    backgroundColor={colors.primary_500}
                    onClick={() => {
                      if (navigate) {
                        switch (fromPage) {
                          case 'hotCandidates':
                            navigate(`/${user.name}/hot-prospects`, {
                              state: { selectedJobOfferFromFilter },
                            });
                            break;
                          case 'todayInterviews':
                            navigate(`/${user.name}/interviews-today`, {
                              state: { selectedJobOfferFromFilter },
                            });
                            break;
                          case 'rejectedCandidates':
                            navigate(`/${user.name}/hot-prospect/rejected`, {
                              state: { selectedJobOfferFromFilter },
                            });
                            break;
                          default:
                            navigate(`/${user.name}/applicants`, {
                              state: { selectedJobOfferFromFilter },
                            });
                            break;
                        }
                      }
                    }}
                  >
                    <Text
                      mt={0}
                      mb={0}
                      fontWeight={700}
                      fontFamily={font.family}
                    >
                      Back to Candidates
                    </Text>
                  </ButtonContainer>
                </Flex>
              </AIContainer>
            </ApplicantsContentWrapper>
          </NavbarAndContentContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </LayoutContainer>
  );
};
