import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth-provider';

const useCheckTrialPeriod = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unprotectedRoutes = [
      '/',
      '/login',
      '/register-user',
      '/register-company',
      '/job-board',
      '/select-plan',
      '/change-plan',
      '/payment-success',
      '/payment-fail',
    ];

    if (unprotectedRoutes.includes(location.pathname)) {
      return;
    }

    if (!user) {
      console.log('User data is not yet available.');

      return;
    }

    if (user.is_blocked) {
      console.log('Company is blocked. Redirecting to Change Plan page.');
      navigate('/change-plan');

      return;
    }

    const createdAt = new Date(user.createdAt);
    const currentDate = new Date();

    if (user.is_on_trial) {
      const timeDifference = currentDate.getTime() - createdAt.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      console.log('Trial Days Difference:', daysDifference);

      if (daysDifference > 7) {
        console.log('Trial period expired. Redirecting to Change Plan page.');
        navigate('/change-plan');
      } else {
        console.log(
          `Trial period still active. ${
            7 - Math.floor(daysDifference)
          } days left.`
        );
      }
    } else {
      const lastSubscriptionPaidAt = new Date(user.last_subscription_payed_at);
      const firstDayOfThisMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastDayOfThisMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      const oneDayInMillis = 1000 * 3600 * 24;

      if (
        lastSubscriptionPaidAt.getTime() >=
          firstDayOfThisMonth.getTime() - oneDayInMillis &&
        lastSubscriptionPaidAt.getTime() <=
          lastDayOfThisMonth.getTime() + oneDayInMillis
      ) {
        console.log('Subscription is valid for this month.');
      } else {
        console.log(
          'Subscription period expired. Redirecting to Change Plan page.'
        );
        navigate('/change-plan');
      }
    }
  }, [user, navigate, location]);

  return null;
};

export default useCheckTrialPeriod;
