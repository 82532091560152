import React, { useState } from 'react';
import {
  DateWrapper,
  FormWrapper,
  Heading,
  Input,
  InputWrapper,
  SurveyFormMainWrapper,
  Text,
  TextArea,
  SmallerText,
  QuestionsWrapper,
  QuestionBox,
  QuestionHeader,
  IconsWrapper,
  QuestionContent,
  DropDown,
  DropDownWrapper,
  Wrapper,
  OptionsBtn,
  OptionWrapper,
  SaveBtn,
  NewQuestionWrapper,
  InputType,
  DeleteIconWrapper,
  StartBtn,
  ButtonWrapper,
} from '../create-survey.styled';
import { EditIconWhite } from '../../../../icons/edit-icon-white';
import { DeleteIconWhite } from '../../../../icons/delete-icon-white';
import { DeleteIcon } from '../../../../icons/delete-icon';
import { PlusIcon } from '../../../../icons/plus-icon-small';
import { DownArrow } from '../../../../icons/down-arrow';
import { SurveyPublishModal } from './survey-publish-modal/survey-publish-modal';

export const SurveyForm: React.FC = () => {
  const [surveyModalVisible, setSurveyModalVisible] = useState<boolean>(false);

  // Example questions  before working with backend logic
  const [questions, setQuestions] = useState([
    {
      id: 1,
      questionText: '',
      selectedQuestionType: '2',
      options: ['Option 1', 'Option 2', 'Option 3'],
    },
    {
      id: 2,
      questionText: '',
      selectedQuestionType: '2',
      options: ['Option 1', 'Option 2', 'Option 3'],
    },
  ]);

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: questions.length + 1,
        questionText: '',
        selectedQuestionType: '2',
        options: ['Option 1', 'Option 2', 'Option 3'],
      },
    ]);
  };

  const handleQuestionTypeChange = (index: number, newType: string) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].selectedQuestionType = newType;
    setQuestions(updatedQuestions);
  };

  const handleAddOption = (index: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push(
      `Option ${updatedQuestions[index].options.length + 1}`
    );
    setQuestions(updatedQuestions);
  };

  const handleDeleteOption = (questionIndex: number, optionIndex: number) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options = updatedQuestions[
      questionIndex
    ].options.filter((_, i) => i !== optionIndex);
    setQuestions(updatedQuestions);
  };

  const handleDeleteQuestion = (questionIndex: number) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((_, index) => index !== questionIndex)
    );
  };

  // Add Save Question and Edit question logic

  return (
    <SurveyFormMainWrapper>
      <Heading>Create Survey</Heading>
      <FormWrapper>
        {/* Survey Details */}
        <InputWrapper>
          <Text>Survey name</Text>
          <Input placeholder="Enter text here" />
        </InputWrapper>
        <InputWrapper>
          <Text>Description</Text>
          <TextArea placeholder="Enter text here" />
        </InputWrapper>

        {/* Survey Date */}
        <InputWrapper>
          <Text>Survey Date</Text>
          <DateWrapper>
            <InputWrapper gap="4px">
              <SmallerText>Start date</SmallerText>
              <Input type="date" width="70%" textTransform="uppercase" />
            </InputWrapper>
            <InputWrapper gap="4px">
              <SmallerText>End date</SmallerText>
              <Input type="date" width="70%" textTransform="uppercase" />
            </InputWrapper>
          </DateWrapper>
          <DateWrapper>
            <InputWrapper gap="4px">
              <SmallerText>Start time</SmallerText>
              <Input type="time" width="70%" textTransform="uppercase" />
            </InputWrapper>
            <InputWrapper gap="4px">
              <SmallerText>End time</SmallerText>
              <Input type="time" width="70%" textTransform="uppercase" />
            </InputWrapper>
          </DateWrapper>
        </InputWrapper>

        {/* Create Questions */}
        <InputWrapper>
          <Text>Create Questions</Text>
          <QuestionsWrapper>
            {questions.map((question, questionIndex) => (
              <QuestionBox key={questionIndex}>
                <QuestionHeader>
                  <Text color="white">Question {questionIndex + 1}</Text>
                  <IconsWrapper>
                    <div style={{ cursor: 'pointer' }}>
                      <EditIconWhite width={16} />
                    </div>
                    <div
                      onClick={() => handleDeleteQuestion(questionIndex)}
                      style={{ cursor: 'pointer' }}
                    >
                      <DeleteIconWhite width={16} />
                    </div>
                  </IconsWrapper>
                </QuestionHeader>
                <QuestionContent>
                  <Wrapper>
                    <Input
                      placeholder="Question name"
                      width="75%"
                      background="white"
                      value={question.questionText}
                      onChange={(e) => {
                        const updatedQuestions = [...questions];
                        updatedQuestions[questionIndex].questionText =
                          e.target.value;
                        setQuestions(updatedQuestions);
                      }}
                    />

                    {/* Dropdown */}
                    <DropDownWrapper>
                      <DropDown
                        value={question.selectedQuestionType}
                        onChange={(e) =>
                          handleQuestionTypeChange(
                            questionIndex,
                            e.target.value
                          )
                        }
                      >
                        <option value="0" disabled>
                          Question type
                        </option>
                        <option value="1">Text response</option>
                        <option value="2">Single choice</option>
                        <option value="3">Multiple choice</option>
                      </DropDown>
                      <DeleteIconWrapper>
                        <DownArrow />
                      </DeleteIconWrapper>
                    </DropDownWrapper>
                  </Wrapper>
                  <InputWrapper width="70%">
                    <Wrapper>
                      <SmallerText>Options:</SmallerText>
                      <OptionsBtn
                        onClick={() => handleAddOption(questionIndex)}
                      >
                        + Add Options
                      </OptionsBtn>
                    </Wrapper>

                    {/* Conditionally rendering inputs */}
                    {question.selectedQuestionType === '1' && (
                      <Input
                        placeholder="Enter your response"
                        width="94%"
                        background="white"
                      />
                    )}

                    {question.selectedQuestionType === '2' && (
                      <div>
                        {question.options.map((option, optionIndex) => (
                          <OptionWrapper key={optionIndex}>
                            <InputType
                              type="radio"
                              name={`singleChoice_${questionIndex}`}
                              value={option}
                            />
                            <Input
                              placeholder={`Option ${optionIndex + 1}`}
                              width="94%"
                              background="white"
                              paddingLeft="25px"
                            />
                            <DeleteIconWrapper
                              onClick={() =>
                                handleDeleteOption(questionIndex, optionIndex)
                              }
                            >
                              <DeleteIcon width={16} />
                            </DeleteIconWrapper>
                          </OptionWrapper>
                        ))}
                      </div>
                    )}

                    {question.selectedQuestionType === '3' && (
                      <div>
                        {question.options.map((option, optionIndex) => (
                          <OptionWrapper key={optionIndex}>
                            <InputType type="checkbox" value={option} />
                            <Input
                              placeholder={`Option ${optionIndex + 1}`}
                              width="94%"
                              background="white"
                              paddingLeft="25px"
                            />
                            <DeleteIconWrapper
                              onClick={() =>
                                handleDeleteOption(questionIndex, optionIndex)
                              }
                            >
                              <DeleteIcon width={16} />
                            </DeleteIconWrapper>
                          </OptionWrapper>
                        ))}
                      </div>
                    )}
                  </InputWrapper>
                </QuestionContent>
                <SaveBtn>Save</SaveBtn>
              </QuestionBox>
            ))}
          </QuestionsWrapper>

          <NewQuestionWrapper onClick={handleAddQuestion}>
            <PlusIcon />
            Add Question
          </NewQuestionWrapper>
        </InputWrapper>
      </FormWrapper>
      <ButtonWrapper>
        {surveyModalVisible && (
          <SurveyPublishModal onClose={() => setSurveyModalVisible(false)} />
        )}
        <StartBtn onClick={() => setSurveyModalVisible(true)}>
          Start Survey
        </StartBtn>
      </ButtonWrapper>
    </SurveyFormMainWrapper>
  );
};
